import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './Loader.css';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Loader = () => (
    <div className="loader-container1">
        <div className="spinner1"></div>
        <p>Loading...</p>
    </div>
);

const ManageCheckConveyance = () => {
    const { status } = useParams();
    const location = useLocation();
    const [EmpCode, setEmpCode] = useState('');
    const [RptMgr, setRptMgr] = useState('');
    const [items, setItems] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');
                    setRptMgr(result.profile.report_mngr || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchItems = async (EmpCode) => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token || !EmpCode) return;

                const response = await fetch(`${API_BASE_URL}getLocalConveyance/${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setItems(result.conveyance_details);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems(EmpCode);
        }
    }, [EmpCode, navigate]);

    useEffect(() => {
        const filtered = items.filter(item => {
            const nameMatch = item.comp_name ? item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const gradeMatch = item.grade ? item.grade.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const deptNameMatch = item.dept_name ? item.dept_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const designationNameMatch = item.designation_name ? item.designation_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const mobileMatch = item.mobile ? item.mobile.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const bankNameMatch = item.bank_name ? item.bank_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const ifscCodeMatch = item.ifsc_code ? item.ifsc_code.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const reportMngrMatch = item.report_mngr ? item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const locationNameMatch = item.location_name ? item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const totalLocalConveyanceMatch = item.total_localconveyance ? item.total_localconveyance.toLowerCase().includes(searchQuery.toLowerCase()) : false;
            const createdAtMatch = item.created_at ? item.created_at.toLowerCase().includes(searchQuery.toLowerCase()) : false;

            const matchesSearch = nameMatch || gradeMatch || deptNameMatch || designationNameMatch || mobileMatch || bankNameMatch || ifscCodeMatch || reportMngrMatch || locationNameMatch || totalLocalConveyanceMatch || createdAtMatch;

            // Filter based on URL path (similar logic as before)
            if (location.pathname === '/manage_conveyance/all') {
                return matchesSearch; // Show all matching items
            } else if (location.pathname === '/manage_conveyance/pending') {
                return item.account_status === 'Pending' && matchesSearch; // Show only pending items
            } else if (location.pathname === '/manage_conveyance/approved') {
                return item.account_status === 'Approved' && matchesSearch; // Show only approved items
            } else if (location.pathname === '/manage_conveyance/rejected') {
                return item.rpt_mgr_status === 'Rejected' && matchesSearch; // Show only rejected items
            } else if (location.pathname === "/manage_conveyance") {
                return matchesSearch;
            }
            return false; // Default case
        });

        // Sorting logic
        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, location.pathname]);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            // Sort by the order in which records appear, using array indices
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            // General sorting based on column value
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const exportToExcel = () => {
        // Use filteredItems to export all the data based on current filtering, not just the visible ones in pagination
        const filteredBranchNames = filteredItems.map(item => ({
            CompleteName: item.comp_name,
            Employeecode: item.emp_code,

            Grade: item.grade,

            ReportingManager: item.report_mngr,

            TotalAmount: Math.trunc(item.total_localconveyance),
            ReportingManagerStatus: item.rpt_mgr_status,
            FinanceStatus: item.account_status,

        }));

        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Conveyance');
        XLSX.writeFile(workbook, 'Local_Conveyance_Details.xlsx');
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString); // Convert string to Date object
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel - Local Conveyance</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><Link to="/dashboard">Home</Link></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Tour/Travel-Advance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <section className="col-lg-12 connectedSortable">
                                    <div className="card">
                                        <div className="card-header">
                                            <span style={{ float: 'right' }}>
                                                <Link to="/add_checklocalconveyance">
                                                    <button type="button" className="btn btn-success">Add New</button>
                                                </Link>
                                                <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                    Export to Excel
                                                </button>
                                            </span>
                                        </div>
                                        <div className="card-body">
                                            {loading ? (
                                                <Loader /> // Show loader if loading is true
                                            ) : (
                                                <>
                                                    <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                        <label>
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <select
                                                            className="form-control custom-select-sm"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>

                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th onClick={() => handleSort("sno")} style={{ cursor: 'pointer', backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent' }}>
                                                                        S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                    </th>
                                                                    <th>Emp Code</th>
                                                                    <th>Trans Code</th>
                                                                    <th>Emp Name</th>
                                                                    <th>Grade</th>
                                                                    <th>Travel Mode</th>
                                                                    <th>Total KM</th>
                                                                    <th>Total Amount</th>
                                                                    <th>Reporting Manager</th>
                                                                    <th>Created at</th>

                                                                    <th>Reporting Manager Status</th>
                                                                    <th>Audit Status</th>
                                                                    <th>Finance Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {currentItems.length > 0 ? (
                                                                    currentItems.map((item, index) => (
                                                                        (item.emp_code === EmpCode) ? (
                                                                            <tr key={index}>
                                                                                <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                <td>{item.emp_code}</td>
                                                                                <td>{item.trans_code}</td>
                                                                                <td>{item.comp_name}</td>
                                                                                <td>{item.grade}</td>
                                                                                <td>{item.transportMethod}</td>
                                                                                <td>
                                                                                    {Math.trunc(item.total_km)}
                                                                                </td>
                                                                                <td>
                                                                                    {Math.trunc(item.total_localconveyance)}
                                                                                </td>

                                                                                <td>{item.report_mngr}</td>
                                                                                <td>{formatDate(item.created_at)}</td>


                                                                                <td style={{ color: item.rpt_mgr_status === 'Pending' ? 'orange' : item.rpt_mgr_status === 'Rejected' ? 'red' : item.rpt_mgr_status === 'Approved' ? 'green' : 'inherit' }}>
                                                                                    {item.rpt_mgr_status}
                                                                                </td>
                                                                                <td style={{ color: item.audit_status === 'Pending' ? 'orange' : item.audit_status === 'Approved' ? 'green' : item.audit_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                                    {item.audit_status}
                                                                                </td>
                                                                                <td style={{ color: item.accounts_status === 'Pending' ? 'orange' : item.accounts_status === 'Approved' ? 'green' : item.accounts_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                                    {item.accounts_status}
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={`/edit_local_conveyance/${item.id}`}>
                                                                                        <i className="fa fa-edit" style={{ fontSize: '12px', color: 'green', marginRight: '10px' }}></i>
                                                                                    </Link>
                                                                                    {/* {item.account_status === 'Pending' && (<a href="#" onClick={(e) => handleDelete(item.id, e)}>
                                                                                        <i className="fa fa-times" style={{ fontSize: '12px', color: 'red' }}></i>
                                                                                    </a>)} */}
                                                                                </td>

                                                                            </tr>
                                                                        ) : null
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        handlePageChange={handlePageChange}
                                    />
                                    <div className="mb-3">
                                        Showing {startItem} to {endItem} of {filteredItems.length} entries
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default ManageCheckConveyance;
