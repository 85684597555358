import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import bgImage from './bg16.png'; // Ensure this path is correct
import logo from './Logos.png'; // Logo import
//import { BASE_URL } from "./Constants";
import MD5 from "crypto-js/md5";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log("check",API_BASE_URL);
const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    emp_code: '',
    password: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
console.log("check_env",process.env);
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(`${API_BASE_URL}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData),
      });
  
      const result = await response.json();
  
      if (!response.ok || !result.status) {
        // If the status is false or response is not OK, login failed
        setResponseMessage(result.error);
        return;
      }
  
      const token = result.token;
      const expirationTime = Date.now() + 7190000;
  
      localStorage.setItem('authToken', token);
      localStorage.setItem('exp', expirationTime);
  
      const profileResponse = await fetch(`${API_BASE_URL}profile`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!profileResponse.ok) {
        setResponseMessage('Error fetching profile');
        return;
      }
      
      const profileResult = await profileResponse.json();
  
      const hashedPassword = MD5('adnet2008').toString();
      const getPassword = profileResult.profile.password;
   
      if(hashedPassword === getPassword){
        navigate('/change_password1');
        console.log("Please change Your Passqword");
        return;
      }
      // Check if the password matches the specific hash
       if (profileResult.status) {
        localStorage.setItem('userProfile', JSON.stringify(profileResult.profile));

        navigate('/dashboard');
      } else {
        setResponseMessage('Error fetching profile: ' + profileResult.message);
      }
  
    } catch (error) {
      setResponseMessage('Error: ' + error.message);
    }
  };
  

  return (
    <main className="container-fluid" style={styles.mainContainer}>
      <div className="row" style={{ height: "100vh" }}>
        {/* Background Image Section */}
        <div className="col-md-6 col-sm-12 position-relative" style={styles.imageContainer}>
          <img src={bgImage} alt="Background" style={styles.backgroundImage} />
          <div style={styles.overlay}></div> {/* Overlay for better text visibility */}
        </div>

        {/* Login Form Section */}
        <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center" style={styles.formContainerWrapper}>
          <div style={styles.formContainer}>
            {/* Logo Section */}
            <div className="login-logo" style={styles.logoContainer}>
              <img src={logo} alt="Travel App" style={{ width: '100%' }} />
              <br />
            </div>

            <center><p className="title" style={styles.title}>Travel App</p></center>
            <p style={styles.loginPrompt}>Please Login To Continue...</p> {/* Added login prompt */}
            
            {responseMessage && (
              <div className="alert alert-danger" role="alert">
                {responseMessage}
              </div>
            )}

            <form onSubmit={handleSubmit} style={styles.form}>
              <div className="form-group">
                <label style={styles.label}>BM ID:</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.emp_code}
                  onChange={handleChange}
                  name="emp_code"
                  placeholder="Enter your BM ID"
                  required
                  style={styles.input}
                />
              </div>
              <div className="form-group">
                <label style={styles.label}>Password:</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  required
                  style={styles.input}
                />
              </div>
              <button type="submit" className="btn btn-block" style={styles.loginButton}>
                Login
              </button>
            </form>
            <hr />
            <a className="forgot" href="#" style={styles.forgotLink}>
              Version: 1.0.1
            </a>
            <center>
              <p style={styles.signupText}>
                Developed by: Vijaya Diagnostic Centre 
              </p>
            </center>
          </div>
        </div>
      </div>
    </main>
  );
};

// Styles
const styles = {
  mainContainer: {
    background: "linear-gradient(to right, #6A82FB, #FC5C7D)",
    height: "100vh",
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: "#ffffff",
  },
  backgroundImage: {
    maxHeight: "100%",
    width: "100%",
    objectFit: "cover",
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: "15px",
    borderBottomLeftRadius: "15px",
  },
  formContainerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "40px",
    backgroundColor: "#f7f7f7",
  },
  formContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "40px",
    borderRadius: "15px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
    width: "100%",
    maxWidth: "400px",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "28px",
    marginBottom: "20px",
    fontFamily: "'Poppins', sans-serif",
    color: "#333",
  },
  loginPrompt: {
    fontSize: "16px",
    marginBottom: "20px",
    color: "#555",
    textAlign: "center",
  },
  form: {
    textAlign: "left",
  },
  label: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#555",
  },
  input: {
    borderRadius: "5px",
    padding: "12px",
    fontSize: "16px",
    marginBottom: "20px",
    border: "1px solid #ddd",
    transition: "border-color 0.3s ease",
  },
  loginButton: {
    backgroundColor: "#6A82FB",
    border: "none",
    padding: "12px 0",
    fontSize: "18px",
    borderRadius: "5px",
    color: "#fff",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.2s ease",
  },
  forgotLink: {
    display: "block",
    marginTop: "20px",
    textAlign: "center",
    color: "#6A82FB",
    textDecoration: "none",
    transition: "color 0.3s ease",
  },
  signupText: {
    marginTop: "15px",
    color: "#666",
  },
  signupLink: {
    color: "#FC5C7D",
    fontWeight: "bold",
    textDecoration: "none",
  },
};

export default Login;
