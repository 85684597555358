import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import useAuth from './useAuth.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import pdficon from "./pdf.png";
import './AddSettlement.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL1 = process.env.REACT_APP_API_BASE_URL1;

const LocalConveyanceForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
       const [error, setError] = useState('');
    const [isApproved, setIsApproved] = useState(false);
    
    const [RptMgrName, setRptMgrName] = useState('');
    const [AdtMgrName, setAdtMgrName] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        mode_of_travel: '',
        to_city: '',
        guesthouse: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',
    });
    const [errors, setErrors] = useState({
        rpt_mgr_status: '', // Initial errors can go here (empty or predefined)
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [EmpCode, setEmpCode] = useState('');
     const [ImgRows, setImgRows] = useState('');
    const [loading, setLoading] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');
    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', day: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);

    useAuth();

    const handleBackClick = () => {
        navigate("/reporting_manager_conveyance");
    };



    const handleTransportChange = (index, e) => {
        const { name, value, type, files } = e.target;
        const updatedTransportRows = [...transportRows];
        if (type === 'file' && files.length > 0) {
            updatedTransportRows[index][name] = files[0];
        } else {
            updatedTransportRows[index][name] = value;
        }
        setTransportRows(updatedTransportRows);
    };

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length > 1) {
            setTransportRows(transportRows.filter((_, i) => i !== index));
        }
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;



        const newValue = name === 'bank_details' ? value.toUpperCase() : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: newValue,
        }));
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getLocalConveyanceByid/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log('Fetched Result:', result); // Debug API response

                if (result.status) {
                    const { conveyance_details, related_details,relational_img_details } = result;
                    setEmpCode(conveyance_details.emp_code || '');
                    setRptMgrName(result.reportManagerName.comp_name || '');
                    setAdtMgrName(result.auditManagerName.comp_name || '');
                    
                    const rpt_mgr_status = result.conveyance_details.rpt_mgr_status || '';
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: conveyance_details.comp_name || '',
                        emp_code: conveyance_details.emp_code || '',
                        dept_name: conveyance_details.dept_name || '',
                        designation_name: conveyance_details.designation_name || '',
                        grade: conveyance_details.grade || '',
                        report_mngr: conveyance_details.report_mngr || '',
                        mobile: conveyance_details.mobile || '',
                        bank_name: conveyance_details.bank_name || '',
                        ifsc_code: conveyance_details.ifsc_code || '',
                        location_name: conveyance_details.location_name || '',
                        total_km: conveyance_details.total_km || '',
                        total_localconveyance: conveyance_details.total_localconveyance || '',
                        rpt_mgr_status: conveyance_details.rpt_mgr_status || '',
                        manager_remarks: conveyance_details.manager_remarks || '',
                        audit_status: conveyance_details.audit_status || '',
                        audit_approved_amount: conveyance_details.audit_approved_amount || '',
                        audit_manager_remarks: conveyance_details.audit_manager_remarks || '',
                        created_at: conveyance_details.created_at || '',
                        manager_approved_date: conveyance_details.manager_approved_date || '',
                        audit_manager: conveyance_details.audit_manager || '',
                        audit_approved_date: conveyance_details.audit_approved_date || '',
                        bank_acnum: conveyance_details.bank_acnum || '',
                    }));
                    setIsApproved(rpt_mgr_status === 'Approved');
                    if (related_details && related_details.length > 0) {
                        // Use the related_details data to set the initial rows
                        setTransportRows(related_details);
                    }
                    if (relational_img_details && relational_img_details.length > 0) {
                        // Use the related_details data to set the initial rows
                        setImgRows(relational_img_details);
                    }
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, [id]);


    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const totalDistanceConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.distance) || 0), 0);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const requestData = {
            ...formData,
            total_localconveyance: totalLocalConveyance,
            total_km: totalDistanceConveyance,
            transportRows, // Include the transport rows in the request
        };

        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch(`${API_BASE_URL}updateLocalConveyanceAM/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            const result = await response.json();

            if (response.ok && result.status) {
                toast.success('Local Conveyance updated successfully!');
                navigate('/audit_conveyance');
            } else {
                toast.error('Failed to update Local Conveyance: ' + result.message);
            }
        } catch (error) {
            toast.error('Error: ' + error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Local Conveyance Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

                                        <li className="breadcrumb-item active">Local Conveyance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #CC5500' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <div className="box-body">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name </label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band</label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department</label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation </label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_name">Bank Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_name"
                                                            id="bank_name"
                                                            value={formData.bank_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_acnum">Bank Ac.No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_acnum"
                                                            id="bank_acnum"
                                                            value={formData.bank_acnum}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="ifsc_code">IFSC Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="ifsc_code"
                                                            id="ifsc_code"
                                                            value={formData.ifsc_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.location_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center><span className='btn btn-primary' style={{ fontSize: '12px', color: 'white', backgroundColor: '#CC5500' }}>Details of Local Conveyance</span></center>

                                        <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            {/* <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button> */}
                                            <table className="expense-table">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="8"></td>
                                                    </tr>
                                                    <tr className="header-row">
                                                        <th style={{ textAlign: 'center' }}>Travel Date</th>
                                                        <th style={{ textAlign: 'center' }}>Day</th>
                                                        <th style={{ textAlign: 'center' }}>Origin</th>
                                                        <th style={{ textAlign: 'center' }}>Destination</th>
                                                        <th style={{ textAlign: 'center' }}>Distance</th>
                                                        <th style={{ textAlign: 'center' }}>Amount</th>
                                                        <th style={{ textAlign: 'center' }}>Transport Mode</th>
                                                        <th style={{ textAlign: 'center' }}>Remarks</th>
                                                        {/* <th style={{ textAlign: 'center' }}>Receipt/Bill</th> */}
                                                        {/* <th>Actions</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transportRows.length > 0 ? (
                                                        transportRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td data-label="Travel Date">
                                                                    <input
                                                                        type="date"
                                                                        name="travelDate"
                                                                        value={row.travelDate}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        max={new Date().toISOString().split("T")[0]}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Day">
                                                                    <input
                                                                        type="text"
                                                                        name="day"
                                                                        value={row.day}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        max={new Date().toISOString().split("T")[0]}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Origin">
                                                                    <input
                                                                        type="text"
                                                                        name="origin"
                                                                        value={row.origin}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="From"
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Destination">
                                                                    <input
                                                                        type="text"
                                                                        name="destination"
                                                                        value={row.destination}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="To"
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Distance">
                                                                    <input
                                                                        type="text"
                                                                        name="distance"
                                                                        value={row.distance}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="To"
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Amount">
                                                                    <input
                                                                        type="number"
                                                                        name="travelAmount"
                                                                        value={row.travelAmount}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td data-label="Transport Mode">
                                                                    <select
                                                                        name="transportMethod"
                                                                        value={row.transportMethod}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font custom-select"
                                                                        disabled
                                                                    >
                                                                        <option value="">Select Mode</option>
                                                                        <option value="bus">Bus</option>
                                                                        <option value="auto">Auto</option>
                                                                        <option value="taxi">Taxi</option>
                                                                        <option value="Car">Own Vehicle-Car</option>
                                                                        <option value="Bike">Own Vehicle-Bike</option>
                                                                        <option value="Company_Car">Company Car</option>
                                                                    </select>
                                                                </td>
                                                                <td data-label="Remarks">
                                                                    <input
                                                                        type="text"
                                                                        name="remarks"
                                                                        value={row.remarks}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        disabled
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                {/* <td data-label="Receipt/Bill">
                                                                    <input
                                                                        type="file"
                                                                        name="receipt"
                                                                        accept=".jpg,.jpeg,.png,.pdf"
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                    />
                                                                    {row.receipt && typeof row.receipt === 'string' && (
                                                                        <a href={`${API_BASE_URL1}assets/uploads/${row.receipt}`} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                            {row.receipt.toLowerCase().endsWith('.pdf') ? (
                                                                                <img
                                                                                    src={pdficon}
                                                                                    alt="Receipt PDF"
                                                                                    style={{ width: '50px', height: '50px', borderRadius: '4px' }}
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={`${API_BASE_URL1}assets/uploads/${row.receipt}`}
                                                                                    alt="Receipt"
                                                                                    style={{ width: '50px', height: '50px', borderRadius: '4px' }}
                                                                                />
                                                                            )}
                                                                        </a>
                                                                    )}
                                                                </td> */}

                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6">No travel details available</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="4" style={{ textAlign: 'right',fontSize: '17px' }}>Grand Total :</td>
                                                        <td style = {{fontSize: '17px'}}><b>{totalDistanceConveyance} km</b></td>
                                                        <td style = {{fontSize: '17px'}}><b>₹{totalLocalConveyance}</b></td>

                                                        <td colSpan="4"></td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>
                                    </fieldset>
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center>
                                            <span className="btn btn-primary" style={{ fontSize: '12px', color: 'white', backgroundColor: '#CC5500' }}>
                                                Additional Documents
                                            </span>
                                        </center>
                                        <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            <table className="document-table" style={{ marginTop: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }}>Click to Preview</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* Loop through ImgRows and display each document's image */}
                                                    {ImgRows.length > 0 ? (
                                                        <tr>
                                                            <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                                                    {ImgRows.map((document) => {
                                                                        // Construct the image URL outside the JSX expression
                                                                        const imageUrl = `${API_BASE_URL1}assets/uploads/${document.img}`;

                                                                        return (
                                                                            <div key={document.id} style={{ margin: '10px' }}>
                                                                                <a href={imageUrl} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                    {document.img.toLowerCase().endsWith('.pdf') ? (
                                                                                        // If the document is a PDF, show the PDF icon
                                                                                        <img
                                                                                            src={pdficon} // Replace with the actual path to your PDF icon
                                                                                            alt={`PDF Document ${document.id}`}
                                                                                            style={{
                                                                                                width: '100px',
                                                                                                height: '100px',
                                                                                                objectFit: 'cover',
                                                                                                margin: '5px',
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        // Otherwise, show the image
                                                                                        <img
                                                                                            src={imageUrl}
                                                                                            alt={`Document ${document.id}`}
                                                                                            style={{
                                                                                                width: '79px',
                                                                                                height: '100px',
                                                                                                objectFit: 'cover',
                                                                                                margin: '5px',
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                {error ? error : 'No documents available.'}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Reporting Manager Status
                                                    
                                                </label>
                                                <select
                                                    name="rpt_mgr_status"
                                                    id="rpt_mgr_status"
                                                    value={formData.rpt_mgr_status || ''}
                                                    onChange={handleChange}
                                                    className="form-control input-font custom-select"
                                                    required
                                                    disabled
                                                >

                                                    <option value="Pending">Pending</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                                {errors.rpt_mgr_status && (
                                                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                        {errors.rpt_mgr_status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="remarks">Reporting Manager Remarks
                                                   
                                                </label>

                                                <textarea name="manager_remarks"
                                                    required
                                                    id="remarks"
                                                    value={formData.manager_remarks || ''}
                                                    onChange={handleChange}
                                                    className="form-control "
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Audit Status
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select
                                                    name="audit_status"
                                                    id="audit_status"
                                                    value={formData.audit_status || ''}
                                                    onChange={handleChange}
                                                    className="form-control input-font custom-select"
                                                    required

                                                >

                                                    <option value="Pending">Pending</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                                {errors.audit_status && (
                                                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                        {errors.audit_status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="name">
                                                    Audit Approved Amount
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="text" // Keep the type as text
                                                    name="audit_approved_amount"
                                                    id="name"
                                                    value={formData.audit_approved_amount !== '' && !isNaN(formData.audit_approved_amount)
                                                        ? Math.floor(Number(formData.audit_approved_amount))
                                                        : ''} // Ensure it's a valid number before applying Math.floor()
                                                    onChange={handleChange}
                                                    onInput={(e) => {
                                                        // Allow only numeric input (no decimal, no special characters)
                                                        const inputValue = e.target.value;
                                                        if (/[^0-9]/.test(inputValue)) {
                                                            // If non-numeric character is found, remove it
                                                            e.target.value = inputValue.replace(/[^0-9]/g, '');
                                                        }
                                                    }}
                                                    className="form-control input-font"
                                                    required
                                                />
                                            </div>


                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="remarks">Audit Remarks
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>

                                                <textarea name="audit_manager_remarks"
                                                    required
                                                    id="audit_manager_remarks"
                                                    value={formData.audit_manager_remarks || ''}
                                                    onChange={handleChange}
                                                    className="form-control "

                                                ></textarea>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="box-footer">
                                        <center>
                                            <button type="submit" disabled={isSubmitting} className="btn btn-success">{isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                            <button className="btn btn-secondary ml-3" onClick={handleBackClick}>Cancel</button>
                                        </center>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <div className="col-sm-12 row" style={{ width: '100%' }}>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            <b>Prepared by </b>
                                            <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                : {formData.created_at}
                                            </p>
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.rpt_mgr_status === 'Approved' && (
                                                <>
                                                    <b> Approved by</b>
                                                    <p> {formData.report_mngr} ({RptMgrName})<br /> Date
                                                        : {formData.manager_approved_date}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.audit_status === 'Approved' && (
                                                <>
                                                    <b> Verified by Audit Team</b>
                                                    <p>                         <span>
                                                        {formData.audit_manager} ({AdtMgrName})  <br /> Date
                                                        : {formData.audit_approved_date}
                                                    </span>
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        {/* <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.account_status === 'Approved' && (
                                                <>
                                                    <b> Verified by Finance Team</b>
                                                    <p>                         <span>
                                                        {formData.accounts_manager} ({AccountsManageName} )   <br /> Date
                                                        : {formData.finance_approved_date}
                                                    </span>
                                                    </p>
                                                </>
                                            )}
                                        </div> */}



                                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default LocalConveyanceForm;
