import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
import './Expence_Request.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Expence_Request = () => {

    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [leave, setLeave] = useState({});
    const [weekoff, setWeekoff] = useState({});
    const [holiday, setHoliday] = useState({});
    const [hasSubmittedStaticData, setHasSubmittedStaticData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const [AccountStatus, setAccountStatus] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [EmpCode, setEmpCode] = useState('100076'); // Default Emp Code
    const [formData, setFormData] = useState({

        branch: '',
        comp_name: '',
        emp_code: '',
        dept_name: '',
        designation_name: '',
        grade: '',
        mobile: '',
        report_mngr: '',
        branch: '',
        email: '',
    });
    useAuth();
    const [dates, setDates] = useState([]);
    const [areasVisited, setAreasVisited] = useState({});
    const [noOfCalls, setNoOfCalls] = useState({});
    const [amounts, setAmounts] = useState({});
    const [remarks, setRemarks] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentMonth, setCurrentMonth] = useState('');  // Current month
    const [selectedMonth, setSelectedMonth] = useState('current');  // Default to 'current' month


    useAuth();
    const navigate = useNavigate();

    // Function to get the dates of a month
    const generateDates = (monthType) => {
        const currentDate = new Date();
        let month = currentDate.getMonth(); // Get current month (0-based, January = 0)
        let year = currentDate.getFullYear();

        if (monthType === 'previous') {
            // If previous month, adjust the month and year
            month = (month === 0) ? 11 : month - 1; // January should go back to December (11)
            if (month === 11) {
                year -= 1; // If month is December, adjust the year
            }
        }

        // Get number of days in the selected month
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const monthDates = [];

        for (let i = 1; i <= daysInMonth; i++) {
            // Format day as two digits (e.g., 01, 02, 03, ... )
            const formattedDay = i < 10 ? `0${i}` : i; // Add leading zero if needed
            const formattedDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${formattedDay}`;
            monthDates.push(formattedDate);
        }

        setDates(monthDates);

        // Set the correct month name and year
        const monthName = currentDate.toLocaleString('default', { month: 'long' });
        const previousMonthName = new Date(year, month).toLocaleString('default', { month: 'long' });

        if (monthType === 'previous') {
            setCurrentMonth(`${previousMonthName} ${year}`);  // Display previous month and year
        } else {
            setCurrentMonth(`${monthName} ${year}`);  // Display current month and year
        }
    };

    // Handle month selection change
    const handleMonthChange = (e) => {
        const selected = e.target.value;
        setSelectedMonth(selected);
        generateDates(selected);  // Generate dates based on the selected month
    };

    useEffect(() => {
        // Initially, load the dates for the current month
        generateDates('current');
    }, []);

    const handleBackClick = () => {
        navigate("/manage_monthly_expences");
    };

    // Handle Leave change
const handleLeaveChange = (date) => {
    setLeave((prevLeave) => {
        const newLeave = !prevLeave[date];
        if (newLeave) {
            // Disable Weekoff and Holiday if Leave is selected
            setWeekoff((prevWeekoff) => ({ ...prevWeekoff, [date]: false }));
            setHoliday((prevHoliday) => ({ ...prevHoliday, [date]: false }));
        }
        return { ...prevLeave, [date]: newLeave };
    });
};

// Handle Weekoff change
const handleWeekoffChange = (date) => {
    setWeekoff((prevWeekoff) => {
        const newWeekoff = !prevWeekoff[date];
        if (newWeekoff) {
            // Disable Leave and Holiday if Weekoff is selected
            setLeave((prevLeave) => ({ ...prevLeave, [date]: false }));
            setHoliday((prevHoliday) => ({ ...prevHoliday, [date]: false }));
        }
        return { ...prevWeekoff, [date]: newWeekoff };
    });
};

// Handle Holiday change
const handleHolidayChange = (date) => {
    setHoliday((prevHoliday) => {
        const newHoliday = !prevHoliday[date];
        if (newHoliday) {
            // Disable Leave and Weekoff if Holiday is selected
            setLeave((prevLeave) => ({ ...prevLeave, [date]: false }));
            setWeekoff((prevWeekoff) => ({ ...prevWeekoff, [date]: false }));
        }
        return { ...prevHoliday, [date]: newHoliday };
    });
};

    // Handle input changes
    const handleChange = (e, date) => {
        const { name, value } = e.target;

        // Prevent negative values for 'no_of_calls' and 'amount'
        if ((name === 'no_of_calls' || name === 'amount') && value < 0) {
            return; // Do nothing if the value is negative
        }

        // Only update the respective state if inputs are not disabled
        if (!leave[date] && !weekoff[date]) {
            if (name === 'areas_visited') {
                setAreasVisited((prevState) => ({
                    ...prevState,
                    [date]: value,
                }));
            } else if (name === 'no_of_calls') {
                setNoOfCalls((prevState) => ({
                    ...prevState,
                    [date]: value,
                }));
            } else if (name === 'amount') {
                setAmounts((prevState) => ({
                    ...prevState,
                    [date]: value,
                }));
            } else if (name === 'remarks') {
                setRemarks((prevState) => ({
                    ...prevState,
                    [date]: value,
                }));
            }
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {

            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const { profile, travel_advance } = result;
                    setEmpCode(profile.emp_code || '');


                    setFormData({
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        mobile: profile.mobile || '',
                        report_mngr: profile.report_mngr || '',
                        branch: profile.location_name || '',
                        email: profile.mail_id || '',
                    });
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Calculate the total number of calls and total amount
        const totalCalls = dates.reduce((total, date) => {
            return total + (Number(noOfCalls[date]) || 0);
        }, 0);

        const totalAmount = dates.reduce((total, date) => {
            return total + (Number(amounts[date]) || 0);
        }, 0);

        // Create FormData instance to append the data
        const formDataToSubmit = new FormData();

        // Append static form data (Name, Employee No, etc.) only once
        if (!hasSubmittedStaticData) {
            Object.keys(formData).forEach((key) => {
                formDataToSubmit.append(key, formData[key]);
            });
            setHasSubmittedStaticData(true); // Mark that static data has been submitted
        }

        // Append the totals to FormData
        formDataToSubmit.append('month', currentMonth);
        formDataToSubmit.append('total_no_of_calls', totalCalls);
        formDataToSubmit.append('total_amount', totalAmount.toFixed(2)); // Round the amount to 2 decimal places

        // Append dynamic rows (only if all fields are filled)
        let validRows = false; // Track if at least one valid row is found

        dates.forEach((date, index) => {
            const areasVisitedValue = areasVisited[date];
            const noOfCallsValue = noOfCalls[date];
            const amountsValue = amounts[date];
            const remarksValue = remarks[date];

            // Check if all fields are filled for this date
            if (areasVisitedValue && noOfCallsValue && amountsValue && remarksValue) {
                // Append the date and all fields only if all fields have values
                formDataToSubmit.append(`dates[${index}][date]`, date);
                formDataToSubmit.append(`dates[${index}][month]`, currentMonth);
                formDataToSubmit.append(`dates[${index}][areas_visited]`, areasVisitedValue);
                formDataToSubmit.append(`dates[${index}][no_of_calls]`, noOfCallsValue);
                formDataToSubmit.append(`dates[${index}][amount]`, amountsValue);
                formDataToSubmit.append(`dates[${index}][remarks]`, remarksValue);

                validRows = true; // Mark that a valid row was found
            }
        });

        // If no valid rows were appended, show an alert or message to the user
        if (validRows) {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}submit_expense`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        // 'Content-Type': 'multipart/form-data' // No need to set content type, it will be set automatically for FormData
                    },
                    body: formDataToSubmit,
                });

                const result = await response.json();
                if (result.status) {
                    navigate('/manage_monthly_expences');
                    setPopupMessage('Expense request submitted successfully!');
                    setShowPopup(true);
                    navigate('/manage_monthly_expences');
                } else {
                    alert(result.error);
                    setPopupMessage(`Error: ${result.message}`);
                    setShowPopup(true);
                }
            } catch (error) {
                console.error('Submit error:', error);

                setPopupMessage('Error: ' + error.message);
                setShowPopup(true);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setIsSubmitting(false);
            setPopupMessage('Please fill out all required fields for at least one date.');
            setShowPopup(true);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Monthly Expense Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

                                        <li className="breadcrumb-item active">Monthly Expense Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                    <div className="box-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Name </label>
                                                    <input
                                                        type="text"
                                                        name="comp_name"
                                                        id="name"
                                                        value={formData.comp_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Name"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="emp_code"
                                                        id="name"
                                                        value={formData.emp_code}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee No/Consultant"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Designation</label>
                                                    <input
                                                        type="text"
                                                        name="designation_name"
                                                        id="name"
                                                        value={formData.designation_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Department&Designation"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Reporting Manager</label>
                                                    <input
                                                        type="text"
                                                        name="report_mngr"
                                                        value={formData.report_mngr}
                                                        className="form-control input-font"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee Email </label>
                                                    <input
                                                        type="text"
                                                        name="grade"
                                                        id="name"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee Email"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                    </label>
                                                    <input

                                                        type="text"
                                                        name="mobile"
                                                        id="name"
                                                        value={formData.mobile}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Days of travel"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Branch</label>
                                                    <input
                                                        type="text"
                                                        name="branch"
                                                        value={formData.branch}
                                                        className="form-control input-font"
                                                        readonly="true"

                                                    />
                                                </div>
                                            </div>
                                            {/* Dropdown for selecting month */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px',color: 'red' }}>Select Month (Please confirm the month before submitting)</label>
                                                    <div className="input-group">
                                                        <select
                                                            className="form-control select"
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                        >
                                                            <option value="current">Current Month</option>
                                                            <option value="previous">Previous Month</option>
                                                        </select>
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">
                                                                <i className="fa fa-chevron-down"></i> {/* Font Awesome down arrow */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Display selected month */}
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px',color: 'red' }}>Month (Check your month here)</label>
                                                    <div
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: '#4CAF50',
                                                            textAlign: 'center',
                                                            backgroundColor: '#f1f1f1',
                                                            padding: '8px',
                                                            borderRadius: '4px',
                                                        }}
                                                    >
                                                        {currentMonth}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Display the dates dynamically based on selected month */}
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12">
                                                <div className="table-container custom-table-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                    <div className="table-responsive" style={{ overflowX: 'auto' }}>
                                                        <table className="table table-hover table-mc-light-blue table-bordered table-striped table-hover table-sticky">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th
                                                                        className="col-md-2"
                                                                        style={{
                                                                            padding: '12px 10px',
                                                                            backgroundColor: '#09b8aa',
                                                                            color: '#fff',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'center',
                                                                            minWidth: '100px',  // Ensure headers don't get too narrow
                                                                        }}
                                                                    >
                                                                        Date
                                                                    </th>
                                                                    <th
                                                                        className="col-md-3"
                                                                        style={{
                                                                            padding: '12px 10px',
                                                                            backgroundColor: '#09b8aa',
                                                                            color: '#fff',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'center',
                                                                            minWidth: '120px',  // Ensure headers don't get too narrow
                                                                        }}
                                                                    >
                                                                        Areas Visited
                                                                    </th>
                                                                    <th
                                                                        className="col-md-1"
                                                                        style={{
                                                                            padding: '12px 10px',
                                                                            backgroundColor: '#09b8aa',
                                                                            color: '#fff',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'center',
                                                                            minWidth: '100px',  // Ensure headers don't get too narrow
                                                                        }}
                                                                    >
                                                                        No of Calls
                                                                    </th>
                                                                    <th
                                                                        className="col-md-1"
                                                                        style={{
                                                                            padding: '12px 10px',
                                                                            backgroundColor: '#09b8aa',
                                                                            color: '#fff',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'center',
                                                                            minWidth: '100px',  // Ensure headers don't get too narrow
                                                                        }}
                                                                    >
                                                                        Amount
                                                                    </th>
                                                                    <th
                                                                        className="col-md-3"
                                                                        style={{
                                                                            padding: '12px 10px',
                                                                            backgroundColor: '#09b8aa',
                                                                            color: '#fff',
                                                                            fontWeight: 'bold',
                                                                            textAlign: 'center',
                                                                            minWidth: '120px',  // Ensure headers don't get too narrow
                                                                        }}
                                                                    >
                                                                        Remarks
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dates.map((date) => (
                                                                    <tr key={date}>
                                                                        <td className="responsive-td" style={{ textAlign: 'center' }}>
                                                                        <b style={{ fontWeight: "900", marginRight: "15px" }}>{date}</b>


                                                                            <label>Leave</label>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={leave[date] || false}
                                                                                onChange={() => handleLeaveChange(date)}
                                                                                disabled={false} // Allow checkbox to be toggled

                                                                            />


                                                                            <label>WeekOff</label>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={weekoff[date] || false}
                                                                                onChange={() => handleWeekoffChange(date)}
                                                                                disabled={false} // Allow checkbox to be toggled

                                                                            />

                                                                            <label>Holiday</label>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={holiday[date] || false}
                                                                                onChange={() => handleHolidayChange(date)}
                                                                                disabled={false} // Allow checkbox to be toggled

                                                                            />


                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                name="areas_visited"
                                                                                value={areasVisited[date] || ''}  // Use empty string if not set
                                                                                onChange={(e) => handleChange(e, date)}
                                                                                className="form-control input-font text-center"
                                                                                placeholder="Areas Visited"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    padding: '8px',
                                                                                    fontSize: '14px',
                                                                                }}
                                                                                disabled={leave[date] || weekoff[date] || holiday[date]}  // Disable input if leave or weekoff is selected
                                                                            required
                                                                            />
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <input
                                                                                type="number"
                                                                                name="no_of_calls"
                                                                                value={noOfCalls[date] || ''}
                                                                                onChange={(e) => handleChange(e, date)}
                                                                                className="form-control input-font text-center"
                                                                                placeholder="Calls"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    padding: '8px',
                                                                                    fontSize: '14px',  // Adjust the font size for mobile view
                                                                                    display: 'block',  // Make the input block-level to center
                                                                                    margin: '0 auto',  // Center the input horizontally
                                                                                }}
                                                                                disabled={leave[date] || weekoff[date] || holiday[date]} 
                                                                                min="0" // Ensures the input cannot go below 0
                                                                            required
                                                                            />
                                                                        </td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <input
                                                                                type="number"
                                                                                name="amount"
                                                                                value={amounts[date] || ''}
                                                                                onChange={(e) => handleChange(e, date)}
                                                                                className="form-control input-font text-center"
                                                                                placeholder="Amount"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    padding: '8px',
                                                                                    fontSize: '14px',  // Adjust the font size for mobile view
                                                                                    display: 'block',
                                                                                    margin: '0 auto',  // Center the input horizontally
                                                                                }}
                                                                                disabled={leave[date] || weekoff[date] || holiday[date]} 
                                                                            required
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                name="remarks"
                                                                                value={remarks[date] || ''}
                                                                                onChange={(e) => handleChange(e, date)}
                                                                                className="form-control input-font text-center"
                                                                                placeholder="Remarks"
                                                                                rows="1" // Single-line textarea
                                                                                style={{
                                                                                    width: '100%',
                                                                                    padding: '8px',
                                                                                    fontSize: '14px',  // Adjust the font size for mobile view
                                                                                }}
                                                                                disabled={leave[date] || weekoff[date] || holiday[date]} 
                                                                            required
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot className="tfoot-light">
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <strong>Total</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {dates.reduce((total, date) => {
                                                                                const calls = Number(noOfCalls[date]) || 0;
                                                                                return total + calls;
                                                                            }, 0)}
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {dates.reduce((total, date) => {
                                                                                const amount = Number(amounts[date]) || 0;
                                                                                return total + amount;
                                                                            }, 0)}
                                                                        </strong>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-success pull-right" disabled={!formData.emp_code}>Submit</button>
                                        <button className="btn btn-secondary ml-3" onClick={handleBackClick}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Expence_Request;
