import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import DatePicker from "react-datepicker";
import './Loader.css';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const Loader = () => (
    <div className="loader-container1">
        <div className="spinner1"></div>
        <p>Loading...</p>
    </div>
);

const ReportingManagerExpence = () => {
    const [EmpCode, setEmpCode] = useState('');
    const [RptMgr, setRptMgr] = useState('');
    const [items, setItems] = useState([]);
    const [auditStatusFilter, setAuditStatusFilter] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [is_audit_approval, setis_audit_approval] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [startDate, setStartDate] = useState(null);  // For date picker
    const [endDate, setEndDate] = useState(null);      // For date picker
    const [sortColumn, setSortColumn] = useState('date');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');

                    setRptMgr(result.profile.report_mngr || '');
                    setis_audit_approval(result.profile.is_audit_approval === 'Y');

                    // fetchItems(result.profile.emp_code, result.profile.report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}getsettlementexpensereports`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems();
        }
    }, [EmpCode]);


    useEffect(() => {
        const filtered = items.filter(item => {
            // Search query matching logic
            const matchesSearchQuery = (
                item.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.designation_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.month.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.total_no_of_calls.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.total_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.rpt_mgr_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.audit_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.account_status.toLowerCase().includes(searchQuery.toLowerCase())
            );
    
            // Date range matching logic
            const matchesDateRange = (
                (!startDate || (item.audit_approved_date && new Date(item.audit_approved_date).setHours(0, 0, 0, 0) >= new Date(startDate).setHours(0, 0, 0, 0))) &&
                (!endDate || (item.audit_approved_date && new Date(item.audit_approved_date).setHours(23, 59, 59, 999) <= new Date(endDate).setHours(23, 59, 59, 999)))
            );
    
            // Return true only if both search query and date range match
            return matchesSearchQuery && matchesDateRange;
        });
    
        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    
        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, auditStatusFilter, startDate, endDate]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);



    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => {
            // Create Date objects and format them to dd-mm-yyyy
            const createdOnDate = new Date(item.created_on);
            const formattedCreatedOnDate = `${String(createdOnDate.getDate()).padStart(2, '0')}-${String(createdOnDate.getMonth() + 1).padStart(2, '0')}-${createdOnDate.getFullYear()}`;

            // Format the audit_approved_date similarly
            const auditApprovedDate = new Date(item.audit_approved_date);
            const formattedAuditApprovedDate = `${String(auditApprovedDate.getDate()).padStart(2, '0')}-${String(auditApprovedDate.getMonth() + 1).padStart(2, '0')}-${auditApprovedDate.getFullYear()}`;

            return {
                EmpCode: item.emp_code,
                EmpRqstDate: formattedCreatedOnDate,  // Use the formatted created_on date here
                AuditApprovedDate: formattedAuditApprovedDate,  // Use the formatted audit_approved_date here
                Name: item.comp_name,
                Designation: item.designation_name,
                Month: item.month,
                Calls: item.total_no_of_calls,
                EmpRqstAmount: Math.trunc(item.total_amount),
                AuditAppovedAmount: Math.trunc(item.audit_approved_amount),
                Remarks: item.audit_remarks,
                // ManagerStatus: item.rpt_mgr_status,
                // AuditStatus: item.audit_status,
                // AccountStatus: item.account_status,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'MonthlyExpenses');
        XLSX.writeFile(workbook, 'Monthly_Expenses.xlsx');
    };




    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Audit Expense Reports</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Manage Monthly Expense</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <section className="col-lg-12 connectedSortable">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row align-items-center">


                                                    <div className="col-md-8 text-right">
                                                        <button type="button" className="btn btn-secondary" onClick={exportToExcel}>
                                                            Export to Excel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                {loading ? (
                                                    <Loader /> // Show loader if loading is true
                                                ) : (
                                                    <>
                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                {/* Search input */}
                                                                <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                                    <label>
                                                                        <input
                                                                            type="search"
                                                                            value={searchQuery}
                                                                            onChange={handleSearchChange}
                                                                            className="form-control form-control-sm"
                                                                            placeholder="Search"
                                                                            aria-controls="example1"
                                                                        />
                                                                    </label>
                                                                </div>

                                                                {/* Items per page dropdown */}
                                                                <div className="form-group" style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                    <select
                                                                        className="form-control custom-select-sm"
                                                                        value={itemsPerPage}
                                                                        onChange={handleItemsPerPageChange}
                                                                    >
                                                                        <option value={10}>10</option>
                                                                        <option value={50}>50</option>
                                                                        <option value={100}>100</option>
                                                                    </select>
                                                                </div>

                                                                {/* Date filter row */}
                                                                <div className="row" style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                                                                    <div className="col-md-5">
                                                                        <div className="form-group">
                                                                            <DatePicker
                                                                                selected={startDate}
                                                                                onChange={(date) => setStartDate(date)}
                                                                                selectsStart
                                                                                startDate={startDate}
                                                                                endDate={endDate}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                className="form-control"
                                                                                placeholderText="Select Start Date"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-5">
                                                                        <div className="form-group">
                                                                            <DatePicker
                                                                                selected={endDate}
                                                                                onChange={(date) => setEndDate(date)}
                                                                                selectsEnd
                                                                                startDate={startDate}
                                                                                endDate={endDate}
                                                                                minDate={startDate}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                className="form-control"
                                                                                placeholderText="Select End Date"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="table-responsive">
                                                            <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            onClick={() => handleSort("sno")}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent',
                                                                                position: 'relative'
                                                                            }}
                                                                        >
                                                                            S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                            {/* Adding arrows for sorting indication */}
                                                                            <span
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    right: '10px',
                                                                                    color: '#007bff',
                                                                                    fontSize: '12px',
                                                                                    textDecoration: 'underline',
                                                                                }}
                                                                            >

                                                                            </span>
                                                                        </th>

                                                                        <th>
                                                                            Emp Code
                                                                        </th>
                                                                        <th>Emp requested Date</th>
                                                                        <th>AuditApproved Date</th>
                                                                        <th>
                                                                            Trans Code
                                                                        </th>

                                                                        <th>
                                                                            Emp Name
                                                                        </th>

                                                                        <th>
                                                                            Designation
                                                                        </th>
                                                                        <th>
                                                                            Month
                                                                        </th>
                                                                        <th>
                                                                            Total Calls
                                                                        </th>
                                                                        <th>
                                                                            Emp Requested Amount
                                                                        </th>
                                                                        <th>
                                                                            Audit Approved Amount
                                                                        </th>
                                                                        <th>
                                                                            Reporting Manager
                                                                        </th>


                                                                        <th>
                                                                            Audit Status
                                                                        </th>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {is_audit_approval && currentItems.length > 0 ? (
                                                                        currentItems.some(item => item.rpt_mgr_status !== 'Rejected') ? (
                                                                            currentItems.map((item, index) => (

                                                                                <tr key={index}>
                                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                    <td>{item.emp_code}</td>
                                                                                    <td>
                                                                                        {(() => {
                                                                                            const date = new Date(item.created_on);
                                                                                            const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
                                                                                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based index, so add 1) and pad
                                                                                            const year = date.getFullYear(); // Get full year
                                                                                            return `${day}-${month}-${year}`; // Return the formatted date
                                                                                        })()}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(() => {
                                                                                            const date = new Date(item.audit_approved_date);
                                                                                            const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
                                                                                            const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based index, so add 1) and pad
                                                                                            const year = date.getFullYear(); // Get full year
                                                                                            return `${day}-${month}-${year}`; // Return the formatted date
                                                                                        })()}
                                                                                    </td>
                                                                                    <td>{item.trans_code}</td>
                                                                                    <td>{item.comp_name}</td>
                                                                                    <td>{item.designation_name}</td>

                                                                                    <td>{item.month}</td>
                                                                                    <td>{item.total_no_of_calls}</td>
                                                                                    <td>{Math.trunc(item.total_amount)}</td>
                                                                                    <td>{Math.trunc(item.audit_approved_amount)}</td>
                                                                                    <td>{item.report_mngr}</td>




                                                                                    <td style={{
                                                                                        color: item.audit_status === 'Pending' ? 'orange' :
                                                                                            item.audit_status === 'Rejected' ? 'red' :
                                                                                                item.audit_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.audit_status}
                                                                                    </td>


                                                                                </tr>

                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                            </tr>
                                                                        )
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />
                                        <div className="mb-3">
                                            Showing {startItem} to {endItem} of {filteredItems.length} entries
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ReportingManagerExpence;
