import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';

import './App.css';
import LoginForm from './LoginForm.js';

import Dashboard from './Dashboard.js';
import Reports from './Reports.js';

import TravelReports from './TravelReports.js';
import TravelDeskReports from './TravelDeskReports.js';
import HotelInfoReports from './HotelInfoReports.js';
import Travel_Manager_Audit from './Travel_Approval_Audit.js';
import Hotel_Manager_Audit from './Hotel_Approval_Audit.js';


import ManagerTravelReports from './Manager_Travel_Reports.js';
import TravelManagerApproval from './Travel_Manager_Approval.js';
import HotelManagerApproval from './Hotel_Manager_Approval.js';

import ManagerHotelReports from './Manager_Hotel_Reports.js';
import HotelReports from './HotelReports.js';

import AuditReports from './AuditReports.js';
import EditLocalConveyance from './Edit_LocalConveyance.js'
import ManageReports from './Manage_Reports.js';
import FinanceAdvanceReports from './FinanceAdvanceReports.js';
import FinanceSettlementReports from './FinanceSettlementReports.js';
import ManageSettlementReports from './Manage_Settlement_Reports.js';
import SettlementReports from './SettlementReports.js';
import Dashboard1 from './Dashboard1.js';
import Home from './Home.js';
import Manage_User from './Manage_User.js';
import Add_User from './AddUser.js';
import Add_City from './AddCity.js';
import Add_Advance from './AddAdvanceForm.js';
import Add_Settlement from './AddSettlement.js';
import Add_Settlement_Group from './Add_Settlement_Group.js';
import Add_State from './AddState.js';

import MyDataTable from './MyDataTable.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import EditUser from './EditUser';


import EditDashboard from './EditDashboard';
import EditAdvance from './EditAdvanceForm';
import EditManageAdvance from './EditManageAdvanceForm';
import EditSettlementForm from './EditSettlementForm';
import EditSettlementFormGroup from './EditSettlementFormGroup';
import EditTravelDesk from './EditTravelDesk';
import EditFinanceSettlementForm from './EditFinanceSettlementForm';

import EditFinanceSettlementFormGroup from './EditFinanceSettlementFormGroup';
import EditManagerSettlementForm from './EditManageSettlementForm.js';

import EditManagerSettlementFormGroup from './EditManageSettlementGroupForm.js';
import EditAuditFormGroup from './EditAuditFormGroup.js';
import EditFinanceForm from './EditFinanceForm.js';
import EditAuditForm from './EditAuditForm.js';


import ManageSettlement from './ManageSettlement';
import ManageAdvanceAccounts from './ManageAdvanceAccounts';
import ManageEmployee from './ManageEmployee';
import ManageStates from './ManageStates';
import ManageCities from './ManageCities';
import ChangePassword from './ChangePassword';
import ChangePassword1 from './ChangePassword1';
import Purpose from './Purpose';
import ManageTravelDesk from './ManageTravelDesk';
import ManageTravelRequest from './ManageTravelRequest';

import ReportingManageTravelRequest from './Reporting_Manager_Travel_Approval';
import ReportingManageHotelRequest from './Reporting_Manager_Hotel_Approval';
import ManageHotelRequest from './ManageHotelRequest';
import ManageHotelInfo from './ManageHotelInfo';
import ManageAdvanceForm from './ManageAdvanceForm';
import FinanceForm from './FinanceForm';
import FinanceSettlementForm from './FinanceSettlementForm';
import AuditForm from './AuditForm';
import OwnAdvanceForm from './OwnAdvanceForm';
import OwnSettlementForm from './SettlementForm.js';
import ProtectedRoute from './ProtectedRoute';
import EditCity from "./EditCity.js";
import ManageDIEM from "./ManageDIEM.js";
import AddDIEM from "./AddDIEM.js";
import AddPurpose from './AddPurpose';
import EditPurpose from './EditPurpose';
import EditDIEM from "./EditDIEM.js";
import EditState from "./EditState.js";
import EditEmployee from "./EditEmployee.js";
import ResetPassword from "./ResetPassword.js";
import Travel_Desk from "./Travel_Desk.js";
import Travel_Request from "./Travel_Request.js";
import Expence_Request from "./Expence_Request.js";
import Hotel_Request from "./Hotel_Request.js";
import Hotel_Info from "./Hotel_Info.js";
import GetTravelDesk from "./GetTravelDesk.js";
import GetTravelRequest from "./GetTravelRequest.js";
import GetHotelRequest from "./GetHotelRequest.js";
import GetHotelInfo from "./GetHotelInfo.js";

import Edit_LocalConveyance_Rm from './Edit_LocalConveyance_Rm.js';
import Edit_Audit_LocalConveyance from './Edit_Audit_LocalConveyance.js';
import Edit_Accounts_LocalConveyance from './Edit_Accounts_LocalConveyance.js';


import Edit_Manager_Travel_Form from './EditManageTravelForm.js';
import Edit_Manager_Hotel_Form from './EditManageHotelForm.js';

import Edit_Travel_Manager_Form from './EditTravelManager.js';
import Edit_Hotel_Manager_Form from './EditHotelManagerForm.js';
import Travel_Manager from './Travel_Manager.js';
import Hotel_Manager from './Hotel_Manager.js';
import ManageMonthlyExpences from './ManageMonthlyExpences.js';
import Edit_Expence_Request from './edit_expence_request.js';
import ReportingManagerExpence from './ReportingManagerExpence.js';
import AuditExpenceApproval from './AuditExpenceApproval.js';
import AuditExpenceReports from './AuditExpenceReports.js';
import FinanceExpenceReports from './FinanceExpenceReports.js';
import EditReportingManagerExpence from './Edit_Manager_Expence.js';
import EditAuditManagerExpence from './Edit_Audit_Manager_Expence.js';
import EditFinanceManagerExpence from './Edit_Finance_Manager_Expence.js';
import ViewAdvanceTravel from './ViewAdvanceForTravel.js';

import FinanceExpenceApproval from './Finance_Expence_Approval.js';
import EditTravelAdvance from './EditTravelAdvance.js';
import Logged_InUsers from './Logged_InUsers.js';
import NotFoundPage from  './NotFoundPage.js';
import SettlementAuditReports from  './SettlementAuditReports.js';
import SettlementExpenseReports from  './SettlementExpenseReports.js';
import SettlementFinanceReports from  './SettlementFinanceReports.js';
import ManageConveyance from './ManageConveyance.js';
import ManageCheckConveyance from './ManageCheckConveyance.js';
import ReportingManagerConveyance from './ReportingManagerConveyance.js';
import AuditConveyance from './AuditConveyance.js';
import FinanceConveyance from './FinanceConveyance.js';


import AddLocalConveyance from './Add_LocalConveyance.js';
import AddCheckLocalConveyance from './Add_CheckLocalConveyance.js';




// import IpCheck from './ip_check.js';








const App = () => {
	// Depend on location to handle refresh flag correctly
	return (

		<Router>


			<Routes>
        <Route path="/" element={<LoginForm />} />
		<Route path="*" element={<NotFoundPage />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/logged_in_users" element={<Logged_InUsers />} />
				<Route path="/reports" element={<Reports />} />
				<Route path="/travel_reports" element={<TravelReports />} />
				<Route path="/travel_desk_reports" element={<TravelDeskReports />} />
				<Route path="/hotel_info_reports" element={<HotelInfoReports />} />
				<Route path="/manager_travel_reports" element={<ManagerTravelReports />} />
				<Route path="/travel_manager_approval" element={<TravelManagerApproval />} />
				<Route path="/hotel_manager_approval" element={<HotelManagerApproval />} />
				<Route path="/manager_hotel_reports" element={<ManagerHotelReports />} />
				{/* <Route path="/ip_check" element={<IpCheck />} /> */}
				<Route path="/hotel_reports" element={<HotelReports />} />
				<Route path="/audit_reports" element={<AuditReports />} />
				<Route path="/manage_reports" element={<ManageReports />} />
				<Route path="/finance_advance_reports" element={<FinanceAdvanceReports />} />
				<Route path="/finance_settlement_reports" element={<FinanceSettlementReports />} />
				<Route path="/manage_settlement_reports" element={<ManageSettlementReports />} />
				<Route path="/settlement_reports" element={<SettlementReports />} />
				<Route path="/settlement_expense_reports" element={<SettlementExpenseReports />} />
				<Route path="/settlement_audit_reports" element={<SettlementAuditReports />} />
				<Route path="/settlement_finance_reports" element={<SettlementFinanceReports />} />
				<Route path="/dashboard1" element={<Dashboard1 />} />
				<Route path="/header" element={<Header />} />
				<Route path="/home" element={<Home />} />
				
				<Route path="/manage_employee" element={<ManageEmployee />} />
				<Route path="/manage_cities" element={<ManageCities />} />
				<Route path="/manage_states" element={<ManageStates />} />
				<Route path="/add_states" element={<Add_State />} />
				<Route path="/add_city" element={<Add_City />} />
				<Route path='/add_diem' element={<AddDIEM />} />
				<Route path="/manage_advance" element={<ManageAdvanceForm />} />
				<Route path="/own_advance" element={<OwnAdvanceForm />} />
				<Route path="/own_settlement" element={<OwnSettlementForm />} />

				<Route path="/manage_advance_accounts" element={<ManageAdvanceAccounts />} />
				<Route path="/manage_settlement" element={<ManageSettlement />} />
				<Route path="/finance_form" element={<FinanceForm />} />
				<Route path="/finance_settlement_form" element={<FinanceSettlementForm />} />
				<Route path="/audit_form" element={<AuditForm />} />

				<Route path="/edit_dashboard/:id" element={<EditDashboard />} />
				<Route path="/edit_advance_form/:id" element={<EditAdvance />} />
				
				<Route path="/edit_manage_advance_form/:id" element={<EditManageAdvance />} />
				<Route path="/edit_travel_desk_form/:id" element={<EditTravelDesk />} />
				<Route path="/edit_settlement_form/:id" element={<EditSettlementForm />} />
				
				<Route path="/edit_settlement_form_group/:id" element={<EditSettlementFormGroup />} />
				<Route path="/edit_manager_settlement_form/:id" element={<EditManagerSettlementForm />} />
				<Route path="/edit_manager_settlement_form_group/:id" element={<EditManagerSettlementFormGroup />} />
				<Route path="/edit_audit_form_group/:id" element={<EditAuditFormGroup />} />
				<Route path="/edit_finance_settlement_form_group/:id" element={<EditFinanceSettlementFormGroup />} />
				<Route path="/edit_finance_form/:id" element={<EditFinanceForm />} />
				<Route path="/edit_audit_form/:id" element={<EditAuditForm />} />
				<Route path="/edit_finance_settlement_form/:id" element={<EditFinanceSettlementForm />} />
				<Route path='/edit_city/:id' element={<EditCity />} />
				<Route path='/edit_travel_advance/:id' element={<EditTravelAdvance />} />
				<Route path='/edit_employee/:id' element={<EditEmployee />} />
				<Route path='/get_travel_desk_form/:id' element={<GetTravelDesk />} />
				<Route path='/get_travel_request_form/:id' element={<GetTravelRequest />} />
				<Route path='/get_hotel_request_form/:id' element={<GetHotelRequest />} />
				<Route path='/get_hotel_info_form/:id' element={<GetHotelInfo />} />
				<Route path='/reset_password/:id' element={<ResetPassword />} />
				<Route path='/edit_state/:id' element={<EditState />} />


				<Route path="/manage_user" element={<ProtectedRoute element={<Manage_User />} requiredType="Y" />} />
				<Route path="/edit_user/:id" element={<ProtectedRoute element={<EditUser />} requiredType="Y" />} />

				<Route path="/mydatatable" element={<MyDataTable />} />
				<Route path="/add_user" element={<Add_User />} />

				<Route path="/travel_desk" element={<Travel_Desk />} />
				<Route path="/travel_request" element={<Travel_Request />} />
				<Route path="/expence_request" element={<Expence_Request />} />
				<Route path="/edit_expence_request/:id" element={<Edit_Expence_Request />} />
				<Route path="/hotel_request" element={<Hotel_Request />} />
				<Route path="/manage_travel_desk" element={<ManageTravelDesk />} />
				<Route path="/manage_travel_request" element={<ManageTravelRequest />} />
				<Route path="/reporting_manager_travel_approval" element={<ReportingManageTravelRequest />} />
				<Route path="/reporting_manager_hotel_approval" element={<ReportingManageHotelRequest />} />
				<Route path="/edit_manager_travel_form/:id" element={<Edit_Manager_Travel_Form />} />
				<Route path="/edit_manager_hotel_form/:id" element={<Edit_Manager_Hotel_Form />} />
				<Route path="/edit_travel_manager_form/:id" element={<Edit_Travel_Manager_Form />} />
				<Route path="/edit_hotel_manager_form/:id" element={<Edit_Hotel_Manager_Form />} />
				<Route path="/travel_approval" element={<Travel_Manager />} />
				<Route path="/hotel_approval" element={<Hotel_Manager />} />
				<Route path="/travel_approval_audit" element={<Travel_Manager_Audit />} />
				<Route path="/hotel_approval_audit" element={<Hotel_Manager_Audit />} />
				
				<Route path="/manage_hotel_request" element={<ManageHotelRequest />} />
				<Route path="/manage_hotel_info" element={<ManageHotelInfo />} />
				<Route path="/hotel_info" element={<Hotel_Info />} />


				<Route path="/purposes" element={<Purpose />} />
				<Route path="/add_advance" element={<Add_Advance />} />
				<Route path="/add_purpose" element={<AddPurpose />} />
				<Route path="/own_advance/all" element={<OwnAdvanceForm />} />
				<Route path="/view_advance_travel" element={<ViewAdvanceTravel />} />
				<Route path="/own_advance/pending" element={<OwnAdvanceForm />} />
				<Route path="/own_advance/approved" element={<OwnAdvanceForm />} />
				<Route path="/own_advance/rejected" element={<OwnAdvanceForm />} />
				<Route path="/manage_travel_request/all" element={<ManageTravelRequest />} />
				<Route path="/manage_travel_request/pending" element={<ManageTravelRequest />} />
				<Route path="/manage_travel_request/approved" element={<ManageTravelRequest />} />
				<Route path="/manage_travel_request/rejected" element={<ManageTravelRequest />} />
				<Route path="/manage_hotel_request/all" element={<ManageHotelRequest />} />
				<Route path="/manage_hotel_request/pending" element={<ManageHotelRequest />} />
				<Route path="/manage_hotel_request/approved" element={<ManageHotelRequest />} />
				<Route path="/manage_hotel_request/rejected" element={<ManageHotelRequest />} />
				<Route path="/own_settlement/all" element={<OwnSettlementForm />} />
				<Route path="/own_settlement/pending" element={<OwnSettlementForm />} />
				<Route path="/own_settlement/approved" element={<OwnSettlementForm />} />
				<Route path="/own_settlement/rejected" element={<OwnSettlementForm />} />
				<Route path="/change_password" element={<ChangePassword />} />
				<Route path="/change_password1" element={<ChangePassword1 />} />
				<Route path="/edit_purpose/:id" element={<EditPurpose />} />
				<Route path="/add_settlement" element={<Add_Settlement />} />
				<Route path="/add_settlement_group" element={<Add_Settlement_Group />} />
				<Route
					path='/manage_diem'
					element={<ManageDIEM />}
				/>
				<Route
					path='/edit_diem/:id'
					element={<EditDIEM />}
				/>
				<Route path="/manage_monthly_expences" element={<ManageMonthlyExpences />} />

				<Route path="/reporting_manager_expences" element={<ReportingManagerExpence />} />
				<Route path="/edit_manager_expence_request/:id" element={<EditReportingManagerExpence />} />
				<Route path="/audit_expence_approval" element={<AuditExpenceApproval />} />
				<Route path="/audit_expence_reports" element={<AuditExpenceReports />} />
				<Route path="/finance_expence_reports" element={<FinanceExpenceReports />} />
				<Route path="/manage_conveyance" element={<ManageConveyance />} />
				<Route path="/manage_check_conveyance" element={<ManageCheckConveyance />} />
				<Route path="/reporting_manager_conveyance" element={<ReportingManagerConveyance />} />
				<Route path="/audit_conveyance" element={<AuditConveyance />} />
				<Route path="/finance_conveyance" element={<FinanceConveyance />} />
				
				<Route path="/edit_rm_local_conveyance/:id" element={<Edit_LocalConveyance_Rm />} />
				<Route path="/edit_audit_local_conveyance/:id" element={<Edit_Audit_LocalConveyance />} />
				<Route path="/edit_accounts_local_conveyance/:id" element={<Edit_Accounts_LocalConveyance />} />
								
				<Route path="/edit_audit_expence_request/:id" element={<EditAuditManagerExpence />} />
				<Route path="/finance_expence_approval" element={<FinanceExpenceApproval />} />
				<Route path="/edit_finance_expence_request/:id" element={<EditFinanceManagerExpence />} />
				<Route path="/add_localconveyance" element={<AddLocalConveyance />} />
				<Route path="/add_checklocalconveyance" element={<AddCheckLocalConveyance />} />
				<Route path="/edit_local_conveyance/:id" element={<EditLocalConveyance />} />

				
				
				
				
				
				

				{/* Add more routes here */}
			</Routes>

		</Router>
	);
};

export default App;


