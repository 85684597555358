import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import useAuth from './useAuth';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LocalConveyanceForm = () => {
    const navigate = useNavigate();
    const [EmpCode, setEmpCode] = useState('');
    const [uploadError, setUploadError] = useState("");
    const [Grade, setGrade] = useState('');
    const [loading, setLoading] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        mode_of_travel: '',
        to_city: '',
        guesthouse: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',
        img: []  // To store the image files
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitAllowed, setIsSubmitAllowed] = useState(true);
    const [transportRows, setTransportRows] = useState([{
        id: 1,
        travelDate: '',
        day: '',
        origin: '',
        destination: '',
        distance: '',
        travelAmount: '',
        transportMethod: '',
        remarks: ''
    }]);

    useAuth();  // Custom hook for authentication

    const handleBackClick = () => {
        navigate("/manage_conveyance");
    };

    const handleAddTransportRow = () => {
        setTransportRows([
            ...transportRows,
            { id: transportRows.length + 1, travelDate: '', day: '', origin: '', destination: '', distance: '', travelAmount: '', transportMethod: '', remarks: '' }
        ]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTransportChange = (index, e) => {
        const { name, value } = e.target;
        const newTransportRows = [...transportRows];

        if (name === 'transportMethod') {
            newTransportRows[index].distance = '';
            newTransportRows[index].travelAmount = '';
        }

        if (name === 'distance' && newTransportRows[index].transportMethod === 'Bike') {
            const distance = parseFloat(value);
            newTransportRows[index].travelAmount = distance > 0 ? (distance * 3.5).toFixed(2) : '';
        }

        if (name === 'travelDate') {
            const date = new Date(value);
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            newTransportRows[index].day = daysOfWeek[date.getDay()];
        }

        newTransportRows[index] = { ...newTransportRows[index], [name]: value };
        setTransportRows(newTransportRows);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const MAX_SIZE = 1 * 1024 * 1024; // 1MB in bytes

        let errorMsg = "";
        const validFiles = files.filter(file => {
            if (file.size > MAX_SIZE) {
                errorMsg = `File "${file.name}" exceeds the 1MB limit. Please upload a smaller file.`;
                return false;
            }
            return true;
        });

        if (errorMsg) {
            setUploadError(errorMsg); // Show error message above button
            toast.error(errorMsg); // Also show toast
            setIsSubmitAllowed(false); // Prevent submission
        } else {
            setUploadError(""); // Clear error if no issue
            setIsSubmitAllowed(true); // Allow submission
        }

        if (validFiles.length === 0) return; // Stop if all files are invalid

        // Convert valid files to Base64
        const fileReaders = validFiles.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileReaders)
            .then(base64Files => {
                setFormData(prevData => ({
                    ...prevData,
                    img: base64Files
                }));
            })
            .catch(error => {
                console.error("Error reading files:", error);
            });
    };


    const handleRemoveTransportRow = (index) => {
        if (transportRows.length > 1) {
            setTransportRows(transportRows.filter((_, i) => i !== index));
        }
    };


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result); // Log to check fetched data

                if (result.status) {
                    const { profile, travel_advance } = result;

                    // Set default values from profile
                    setEmpCode(profile.emp_code || '');
                    setGrade(profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        report_mngr: profile.report_mngr || '',
                        mobile: profile.mobile || '', // Do not display the password
                        bank_acnum: profile.bank_acnum || '',
                        bank_name: profile.bank_name || '',
                        ifsc_code: profile.ifsc_code || '',
                        location_name: profile.location_name || '',
                    }));


                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);
    const totalLocalConveyance = Math.round(transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0));
    const totalDistanceConveyance = Math.round(transportRows.reduce((acc, row) => acc + (parseFloat(row.distance) || 0), 0));
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isSubmitAllowed) {
            toast.error("Please fix the file size errors before submitting.");
            return; // Do not submit if files are invalid
        }
        if (isSubmitting) return;
        setIsSubmitting(true);

        const dataToSubmit = {
            ...formData,
            transportRows: transportRows,
            total_localconveyance: totalLocalConveyance,  // Add the total local conveyance
            total_km: totalDistanceConveyance
        };

        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}postLocalConveyance`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'  // Sending JSON payload
                },
                body: JSON.stringify(dataToSubmit) // Convert the data to JSON format
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Local Conveyance Form submitted successfully!');
                navigate('/manage_conveyance');
            } else {
                toast.error(result.message || 'An error occurred while submitting the form.');
            }
        } catch (error) {
            console.error('Submission error:', error);
            toast.error('Failed to submit form');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Local Conveyance Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active">Local Conveyance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #CC5500' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <div className="box-body">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name </label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band</label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department</label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation </label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_name">Bank Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_name"
                                                            id="bank_name"
                                                            value={formData.bank_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_acnum">Bank Ac.No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_acnum"
                                                            id="bank_acnum"
                                                            value={formData.bank_acnum}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="ifsc_code">IFSC Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="ifsc_code"
                                                            id="ifsc_code"
                                                            value={formData.ifsc_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.location_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center>
                                            <span className="btn btn-warning" style={{ fontSize: '12px', color: 'white', backgroundColor: '#CC5500' }}>
                                                Details of Local Conveyance
                                            </span>
                                        </center>

                                        <div className="transport-table-container mb-3 text-right">
                                            <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button>

                                            {/* Responsive Table Wrapper */}
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td colSpan="12"></td>
                                                        </tr>
                                                        <tr className="header-row text-center">
                                                            <th>Travel Date<span className="text-danger">*</span></th>
                                                            <th>Day</th>
                                                            <th>Origin<span className="text-danger">*</span></th>
                                                            <th>Destination<span className="text-danger">*</span></th>
                                                            <th>Transport Mode<span className="text-danger">*</span></th>
                                                            <th>Distance KM<span className="text-danger">*</span></th>
                                                            <th>Amount<span className="text-danger">*</span></th>
                                                            <th>Remarks</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transportRows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <input
                                                                        type="date"
                                                                        name="travelDate"
                                                                        value={row.travelDate}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        max={new Date().toISOString().split("T")[0]}
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="day"
                                                                        value={row.day}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Day"
                                                                        required={index === 0}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="origin"
                                                                        value={row.origin}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="From"
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="destination"
                                                                        value={row.destination}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="To"
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        name="transportMethod"
                                                                        value={row.transportMethod}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        required={index === 0}
                                                                    >
                                                                        <option value="">Select Mode</option>
                                                                        <option value="bus">Bus</option>
                                                                        <option value="auto">Auto</option>
                                                                        <option value="taxi">Taxi</option>
                                                                        <option value="Car">Own Vehicle-Car</option>
                                                                        <option value="Bike">Own Vehicle-Bike</option>
                                                                        <option value="Company_Car">Company Car</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="distance"
                                                                        value={row.distance}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Enter distance"
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="travelAmount"
                                                                        value={row.travelAmount}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                        required={index === 0}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="remarks"
                                                                        value={row.remarks}
                                                                        onChange={(e) => handleTransportChange(index, e)}
                                                                        className="form-control input-font"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button type="button" onClick={() => handleRemoveTransportRow(index)} className="btn btn-danger">×</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colSpan="5" className="text-right font-weight-bold">Grand Total :</td>
                                                            <td><b>{totalDistanceConveyance} km</b></td>
                                                            <td><b>₹{totalLocalConveyance}</b></td>
                                                            <td colSpan="5"></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </fieldset>


                                    {/* Upload Images Section */}
                                    <section className="col-lg-12">
                                        {uploadError && <div className="alert alert-danger text-center p-2">{uploadError}</div>}

                                        <label className="font-weight-bold">Upload Multiple Images:</label>
                                        <input
                                            type="file"
                                            name="img"
                                            multiple
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            className="form-control mb-3"
                                        />

                                        {/* Buttons */}
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-success">
                                                {isSubmitting ? 'Submitting...' : 'Submit'}
                                            </button>
                                            <button type="button" className="btn btn-secondary ml-3" onClick={handleBackClick}>
                                                Cancel
                                            </button>
                                        </div>
                                    </section>

                                </form>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default LocalConveyanceForm;
