import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import useAuth from './useAuth';
import './AddSettlement.css';
import icon1 from "./picture.png";
import pdficon from "./pdf.png";
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const EditSettlementFormGroup = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [EmpCode, setEmpCode] = useState('');
    const [PerDiem, setPerDiem] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [flashAlert, setFlashAlert] = useState(null);
    const [MultipleRows, setMultipleRows] = useState([]);
    const [Grade, setGrade] = useState('');
    const [grades, setGrades] = useState([]);
    const [rows4, setrows4] = useState([]);
    const [rows1, setrows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rows3, setRows3] = useState([]);
    useAuth();
    const [documentsRows, setdocumentsRows] = useState([]);

    const [Amount, setAmount] = useState('');
    const [Purpose, setPurpose] = useState('');
    const [ReportManageName, setReportManageName] = useState('');
    const [AuditManageName, setAuditManageName] = useState('');
    const [AccountsManageName, setAccountsManageName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    const [employeeRows, setEmployeeRows] = useState([{ id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade1: '', mobile: '' }]);
    const [travelRows, setTravelRows] = useState([{ id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', perDiem1: '', local: '', misc: '', totalAmount: '' }]);
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [empCodes, setEmpCodes] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    const [Hotel, setHotel] = useState('');
    const [nextId, setNextId] = useState(1);
    const [Diem, setDiem] = useState('');
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [formData, setFormData] = useState({
        days: '', travel_amount: 0, Group: 'Shared', bank_acnum: '',
        bank_name: '',
        ifsc_code: ''
    });
    const [rows, setRows] = useState([{ id: Date.now(), fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', ticket_travel_booked_by: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0, perDiem1: 0, local: 0, totalAmount: 0 }]);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    toast.error(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                toast.error('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, [id]);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token12", token);
                const response = await fetch(`${API_BASE_URL}getsettlement?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Settlement data:", result);

                if (result.status) {
                    setEmpCode(result.settlement.emp_code || '');
                    setPerDiem(result.settlement.grandTotalPerDiem || '');
                    setGrade(result.settlement.grade || '');
                    setReportManageName(result.reportManagerName.comp_name || '');
                    setAuditManageName(result.auditManagerName.comp_name || '');
                    setAccountsManageName(result.accountsManagerName.comp_name || '');
                    setPurpose(result.settlement.purpose || '');
                    setAmount(result.settlement.travel_amount || '');
                    const report_mngr = result.settlement.report_mngr || '';
                    setFormData({
                        comp_name: result.settlement.comp_name || '',
                        guesthouse: result.settlement.guesthouse || '',

                        emp_code: result.settlement.emp_code || '',
                        dept_name: result.settlement.dept_name || '',
                        designation_name: result.settlement.designation_name || '',
                        purpose: result.settlement.purpose || '',
                        mode_of_travel: result.settlement.mode_of_travel || '',
                        travel_amount: result.settlement.travel_amount || '',
                        already_taken_advance: result.settlement.already_taken_advance || '',
                        days: result.settlement.days || '',
                        settlement_purpose: result.settlement.settlement_purpose || '',
                        city: result.settlement.city || '',
                        travel_advance: result.settlement.travel_advance || '',
                        location_name: result.settlement.location_name || '',
                        //  bank_details: result.data.bank_details || '',
                        grade: result.settlement.grade || '',
                        mobile: result.settlement.mobile || '',
                        rpt_mgr_status: result.settlement.rpt_mgr_status || '',
                        report_mngr: report_mngr,
                        manager_remarks: result.settlement.manager_remarks || '',
                        manager_approved_amount: result.settlement.manager_approved_amount || '',
                        finance_approved_amount: result.settlement.finance_approved_amount || '',
                        finance_remarks: result.settlement.finance_remarks || '',
                        account_status: result.settlement.account_status || '',
                        audit_status: result.settlement.audit_status || '',
                        audit_approved_amount: result.settlement.audit_approved_amount || '',
                        audit_remarks: result.settlement.audit_remarks || '',
                        audit_manager: result.settlement.audit_manager || '',
                        accounts_manager: result.settlement.accounts_manager || '',
                        utr: result.settlement.utr || '',
                        utr_date: result.settlement.utr_date || '',
                        created_on: result.settlement.created_on || '',
                        manager_approved_date: result.settlement.manager_approved_date || '',
                        audit_approved_date: result.settlement.audit_approved_date || '',
                        finance_approved_date: result.settlement.finance_approved_date || '',
                        bank_acnum: result.settlement.bank_acnum || '',
                        bank_name: result.settlement.bank_name || '',
                        ifsc_code: result.settlement.ifsc_code || '',
                        settlement_flag: 1,

                    });
                    //  fetchReportingData(report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, [id]);

    useEffect(() => {
        const fetchSettlement1 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id1=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setrows1(result.settlement1);
                    setNextId(result.settlement1.length + 1);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch data');
            }
        };

        fetchSettlement1();
    }, [id]);

    useEffect(() => {
        const fetchSettlement2 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id2=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const result = await response.json();
                console.log("travelid", result.settlement2);
                if (result.status) {
                    setRows2(result.settlement2);
                    // Initialize transportRows with data from the fetched settlement

                    if (result.settlement2.length > 0) {
                        const initialTransportRows = result.settlement2.map((settlement, index) => ({

                            id: settlement.id,
                            travelId: settlement.travel_id || '',
                            travelDate: settlement.travelDate || '',
                            origin: settlement.origin || '',
                            destination: settlement.destination || '',
                            travelAmount: settlement.travelAmount || '',
                            transportMethod: settlement.transportMethod || '',
                            receipt: settlement.receipt || ''
                        }));

                        setTransportRows(initialTransportRows);
                    }
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchSettlement2();
    }, [id]);

    useEffect(() => {
        const fetchSettlement3 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id3=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("travelid", result.settlement2);
                if (result.status) {
                    setRows3(result.settlement3);
                    // Initialize transportRows with data from the fetched settlement

                    if (result.settlement3.length > 0) {
                        const initialTransportRows = result.settlement3.map((settlement, index) => ({

                            id: settlement.id,
                            travelId: settlement.travel_id || '',
                            travelDate: settlement.travelDate || '',
                            origin: settlement.origin || '',
                            destination: settlement.destination || '',
                            travelAmount: settlement.travelAmount || '',
                            transportMethod: settlement.transportMethod || '',
                            receipt: settlement.receipt || ''
                        }));

                        // setTransportRows(initialTransportRows);
                    }
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                // setError('Failed to fetch data');
            }
        };

        fetchSettlement3();
    }, [id]);

    useEffect(() => {
        const fetchSettlement4 = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}getsettlement?travel_id4=${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setdocumentsRows(result.settlement4);
                    // Initialize transportRows with data from the fetched settlement


                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                // setError('Failed to fetch data');
            }
        };

        fetchSettlement4();
    }, [id]);

    const handleChange2 = (e) => {
        const { name, value, type, files } = e.target;



        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'file' ? files[0] : value
        }));


        if (name === 'status_select') {
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };

                // Based on the selected request type, set the corresponding status to 'Pending'
                if (value === 'request_for_manager') {
                    newData.rpt_mgr_status = 'Pending';
                } else if (value === 'request_for_audit_mngr') {
                    newData.audit_status = 'Pending';
                } else if (value === 'request_for_account_mngr') {
                    newData.account_status = 'Pending';
                }

                return newData;
            });
        }

        // Handle other field changes (rpt_mgr_status, audit_status, account_status, etc.)
        else if (name === 'rpt_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'audit_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'account_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };


    const handleChange = async (id, event) => {

        const { name, value } = event.target;
        const updatedRows = rows1.map(row => row.id === id ? { ...row, [name]: value } : row);

        setRows(updatedRows);



        if (name === 'days') {
            const days = parseFloat(value) || 0;
            const firstRow = updatedRows[0];

            // Reset perDiem and perDiem1
            if (firstRow) {
                firstRow.perDiem = 0;
                firstRow.perDiem1 = 0;
                firstRow.to_city = '';
            }

            const empSize = (employeeRows.length + 1);



            // Reset 'toPlace' (city) to an empty string or default value
            setFormData(prevData => ({
                ...prevData,
                [name]: value,  // Update 'days' value
                toPlace: ''     // Reset 'toPlace'
            }));

        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }

        if (name === 'groupSelf') {
            if (value === 'Self') {
                navigate('/add_settlement'); // Adjust the path as necessary
            } else if (value === 'Shared') {
                navigate('/add_settlement_group'); // Adjust the path as necessary
            }
        }

        // Update the state
        setRows(updatedRows); // Update rows state
        setFormData((prevData) => ({ ...prevData, [name]: value })); // Update formData
    };

    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { travelId: id, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
        updateLocalConveyanceTotals();
    };
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const handleTransportChange = (index, e) => {
        const { name, value, type, files } = e.target;
        const newTransportRows = [...transportRows];

        // Update the transport row with either file or other data
        if (type === 'file' && files.length > 0) {
            newTransportRows[index] = { ...newTransportRows[index], [name]: files[0] }; // Store the file object
        } else {
            newTransportRows[index] = { ...newTransportRows[index], [name]: value }; // Update other values
        }

        setTransportRows(newTransportRows);


    };

    const handleRemoveTransportRow = async (id1, travelAmount, totalAmount) => {
        // Check if row is empty
        const rowToRemove = transportRows.find(row => row.id === id1);

        if (!rowToRemove ||
            (!rowToRemove.travelDate &&
                !rowToRemove.origin &&
                !rowToRemove.destination &&
                !rowToRemove.travelAmount &&
                !rowToRemove.transportMethod &&
                !rowToRemove.receipt)) {
            // If the row is empty, remove it directly from state
            setTransportRows(prevRows => prevRows.filter(row => row.id !== id1));
            toast.success("Transport row deleted successfully.");
            return;
        }

        const Amount1 = totalAmount - travelAmount;
        const userConfirmed = window.confirm(`Are you sure you want to delete this transport row?`);

        if (!userConfirmed) return; // Exit if user cancels

        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${API_BASE_URL}setSettlement2Delete?id=${id1}&id1=${id}&total=${Amount1}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setTransportRows(prevRows => prevRows.filter(row => row.id !== id1));
                toast.success("Transport row deleted successfully.");
                navigate(0);
            } else {
                toast.error(result.message || "Failed to update transport row status.");
            }
        } catch (error) {
            console.error('Error setting transport row status inactive:', error);
            toast.error("Failed to set transport row status inactive.");
        }
    };

    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
    const updatePerDiemForRows = (rows, diemData, days) => {

        const { hotelPerHotel, hotelPerDiem } = diemData; // Extract both values from the object
        const empSize = (employeeRows.length + 1);
        const updatedRows = rows.map((row, index) =>
            index === 0
                ? { ...row, perDiem: (hotelPerHotel * days * empSize).toFixed(2), perDiem1: (hotelPerDiem * days * empSize).toFixed(2) }
                : row
        );
        console.log("check", updatedRows);
        setrows1(updatedRows);
    };


    const handleCityChange = async (id, e) => {
        if (!e || !e.target) {
            console.error('Event or target is undefined', e);
            return;
        }

        const { name, value: selectedCity } = e.target;

        const updatedRows = rows1.map(row =>
            row.id === id ? { ...row, [name]: selectedCity } : row
        );
        setrows1(updatedRows);

        // if (id === rows1[0].id && selectedCity) {
        //     const diemData = await fetchDiem(selectedCity);
        //     console.log("checkdiem", diemData);
        //     if (diemData) {
        //         // Update per diem and hotel based on the new city value
        //         updatePerDiemForRows(updatedRows, diemData, formData.days);
        //     } // Update per diem based on new city value
        // }
    };

    const addRow = () => {
        const newRow = { id: Date.now(), fromPlace: '', toPlace: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const grandTotal = () => {
        const totalHotelAmount = rows1.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const totalTravelAmount = rows1.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        const totalMisc = rows1.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const totalPerDiem = rows1.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        // Calculate total per diem based on booking method

        // const totalPerDiem = rows1.reduce((total, row) => {
        //     // Check if hotel_booked_by is "TravelDesk" and get the appropriate per diem value
        //     const perDiemValue = row.hotel_booked_by === "TravelDesk"
        //         ? parseFloat(row.perDiem1) || 0  // Fallback to 0 if NaN
        //         : parseFloat(row.perDiem) || 0;  // Fallback to 0 if NaN

        //     return total + perDiemValue;  // Add to total
        // }, 0);

        console.log("checktotalperdiem", totalPerDiem);

        return totalHotelAmount + totalTravelAmount + totalMisc + totalPerDiem + totalLocalConveyance;
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                console.log("Profile data1:", result);

                if (result.status) {
                    const report_mngr = result.profile.report_mngr || '';
                    const emp_code = result.profile.emp_code || '';
                    setEmpCode(result.profile.emp_code || '');
                    setGrade(result.profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: result.profile.comp_name || '',
                        emp_code: result.profile.emp_code || '',
                        dept_name: result.profile.dept_name || '',
                        designation_name: result.profile.designation_name || '',
                        grade: result.profile.grade || '',
                        report_mngr: result.profile.report_mngr || '',
                        mobile: result.profile.mobile || '', // Do not display the password
                    }));
                    //fetchReportingData(emp_code);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4|4\.5|5|5\.5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const handleSelectCode = (code) => {

        setRows(prevRows =>
            prevRows.map(row => (row.id === currentRowId ? { ...row, emp_code: code } : row))
        );
        setCurrentInput(code);
        setFilteredCodes([]);
        fetchEmployeeDetails(code);
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
        if (name === 'emp_code') {
            const emp_code = parseFloat(value) || 0;
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };




    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.status) {
                const fetchedGrade = result.user.grade;
                console.log("fetchedGrade", fetchedGrade);

                // Update the rows with the fetched employee details
                setRows3(prevRows => {
                    return prevRows.map(row => row.emp_code === empCode ? {
                        ...row,
                        comp_name: result.user.comp_name,
                        dept_name: result.user.dept_name,
                        designation_name: result.user.designation_name,
                        grade: fetchedGrade,
                        mobile: result.user.mobile,
                    } : row);
                });

                // Optionally update the grades state
                setGrades(prevGrades => {
                    if (!prevGrades.includes(fetchedGrade)) {
                        return [...prevGrades, fetchedGrade];
                    }
                    return prevGrades;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };
    const grades1 = rows3.map(row => row.grade); // Array from rows3
    const grades2 = Array.isArray(formData.grade) ? formData.grade : [formData.grade]; // Ensure formData.grade is an array

    // Combine both arrays
    const combinedGrades1 = grades1.concat(grades2);

    console.log("checkgrades1", combinedGrades1);
    // Example function to get all grades as a single object
    const getCombinedGradesObject = () => {
        return grades.reduce((acc, grade, index) => {
            acc[`grade${index + 1}`] = grade; // Create keys like grade1, grade2, etc.
            return acc;
        }, {});
    };

    // Usage example:
    const combinedGrades = getCombinedGradesObject();
    const firstChars = Object.values(combinedGrades).map(grade => grade.charAt(0));

    console.log(firstChars);
    console.log("Combined Grades Object:", firstChars);

    const fetchDiem = async (selectedCity) => {
        try {
            const token = localStorage.getItem('authToken');
            const cityClassResponse = await fetch(`${API_BASE_URL}getCityClass?id=${selectedCity}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!cityClassResponse.ok) throw new Error('Network response was not ok');
            const cityClassData = await cityClassResponse.json();
            const cityClass = cityClassData.data.city_class || '';
            const checkinggrade1 = rows3.map(row => row.grade);
            const checkinggrade2 = Array.isArray(formData.grade) ? formData.grade : [formData.grade];
            const mergedGrades = [...checkinggrade1, ...checkinggrade2].map(grade => String(grade).charAt(0));

            console.log("mergedGrades", mergedGrades);
            console.log("checkgrds", String(checkinggrade1).charAt(0));
            console.log("checkgrds", String(checkinggrade2).charAt(0));

            const grades = ['D', 'C']; // Modify as needed for grades

            // Fetching per diem for each grade
            const perDiemPromises = mergedGrades.map(async (grade) => {
                const modifiedGrade = grade.charAt(0);
                const diemResponse = await fetch(`${API_BASE_URL}getDiem?grade=${modifiedGrade}&city_class=${cityClass}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!diemResponse.ok) throw new Error('Network response for diem API was not ok');
                const diemData = await diemResponse.json();
                return {
                    grade: modifiedGrade,
                    hotelPerHotel: parseFloat(diemData.data.hotel) || 0,  // Convert to number
                    hotelPerDiem: parseFloat(diemData.data.diem) || 0     // Convert to number
                };
            });

            const perDiemResults = await Promise.all(perDiemPromises);

            console.log("perDiemResults", perDiemResults);

            // Calculate total amounts
            const totalHotelPerHotel = perDiemResults.reduce((sum, result) => sum + result.hotelPerHotel, 0);
            const totalHotelPerDiem = perDiemResults.reduce((sum, result) => sum + result.hotelPerDiem, 0);

            // Update the rows1 state to reflect the new perDiem values
            setrows1(prevRows =>  // Use setRows1 to update the rows1 state
                prevRows.map((row, index) => {
                    const perDiemForGrade = perDiemResults[index] || {}; // Get corresponding per diem for grade
                    return {
                        ...row,
                        perDiem: (totalHotelPerHotel * (parseFloat(formData.days) || 0)).toFixed(2),  // Store calculated value in perDiem
                        perDiem1: (totalHotelPerDiem * (parseFloat(formData.days) || 0)).toFixed(2),  // Store calculated value in perDiem1
                        grade: perDiemForGrade.grade  // Store the grade for that row
                    };
                })
            );

        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error('Error fetching data');
        }
    };


    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const MAX_SIZE = 1 * 1024 * 1024; // 1MB in bytes

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Convert FileList to Array
        const updatedDocuments = [];

        files.forEach((file) => {
            if (file.size > MAX_SIZE) {
                // Show the flash alert if the file exceeds the size limit
                const errorMessage = `File "${file.name}" exceeds the 1MB limit. Please upload a smaller file.`;
                setFlashAlert(errorMessage);

                // Hide the flash alert after 5 seconds
                setTimeout(() => {
                    setFlashAlert(null); // Remove the alert after 5 seconds
                }, 5000);

                return; // Skip adding this file
            }

            // If file is valid, add it to the documents array
            updatedDocuments.push({
                travel_id: 29, // Static travel_id (can be dynamically set)
                additional_doc: file, // The file object
            });
        });

        // Only update the state if there are valid documents
        if (updatedDocuments.length > 0) {
            setMultipleRows((prevRows) => [...prevRows, ...updatedDocuments]);
        }

        // Reset input value after processing
        e.target.value = '';
        setSelectedFiles(files);
    };


    const handleBackClick = () => {
        navigate("/own_settlement");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateDays()) {
            return; // Prevent submission if validation fails
        }
        for (const row of transportRows) {
            if (row.travelAmount > 300 && !row.receipt) {
                toast.error(`Receipt is mandatory for travel amount greater than 300 for the entry from ${row.origin} to ${row.destination}.`);
                return; // Stop submission
            }
        }

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

        const totalAmount = rows.reduce((total, row) => parseFloat(total) + (
            (parseFloat(row.hotel_amount) || 0) +
            (parseFloat(row.ticket_travel_amount) || 0) +
            (parseFloat(row.perDiem) || 0) +
            (parseFloat(row.local) || 0) +
            (parseFloat(row.misc) || 0)
        ), 0) + parseFloat(totalLocalConveyance);

        // Append the total amount to formData
        formDataToSubmit.append('TotalAmount', totalAmount);

        //formDataToSubmit.append('TotalAmount', adjustedTotalAmount);
        rows1.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Skip the id for newly added rows
                if (key === 'id') {
                    if (row.addFlag === 1) { // Check if it's a newly added row
                        return; // Do not append id for new rows
                    } else {
                        formDataToSubmit.append(`rows[${index}][${key}]`, row[key]); // Append existing id
                    }
                } else {
                    formDataToSubmit.append(`rows[${index}][${key}]`, row[key]); // Append other row values
                }
            });
        });

        // Append transportRows data
        transportRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Check if the key corresponds to a file
                if (key === 'receipt' && row[key]) {
                    formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]); // Append the file object
                } else {
                    formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]); // Append other values
                }
            });
        });

        MultipleRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Check if the key corresponds to a file
                if (key === 'additional_doc' && row[key]) {
                    formDataToSubmit.append(`MultipleRows[${index}][${key}]`, row[key]); // Append the file object
                }
            });
        });

        // Append transportRows data
        rows3.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                // Check if the key corresponds to a file
                if (key === 'receipt' && row[key]) {
                    formDataToSubmit.append(`rows3[${index}][${key}]`, row[key]); // Append the file object
                } else {
                    formDataToSubmit.append(`rows3[${index}][${key}]`, row[key]); // Append other values
                }
            });
        });


        // Debugging logs
        const formDataObject = {};
        formDataToSubmit.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            if (formDataObject[key]) {
                // If the key exists, convert it to an array if it's not already
                if (!Array.isArray(formDataObject[key])) {
                    formDataObject[key] = [formDataObject[key]];
                }
                // Push the new value to the array
                formDataObject[key].push(value);
            } else {
                formDataObject[key] = value;
            }
        });
        console.log('Form Data to Submit:', JSON.stringify(formDataObject, null, 2));

        try {
            const token = localStorage.getItem('authToken');
            console.log("checktoken", token);

            const response = await fetch(`${API_BASE_URL}updateSettlementGroup/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
            console.log("Response Data:", result);

            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({ emp_code: '' }); // Reset other fields as necessary
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    const handleAddEmpRow = () => {

        setRows3([...rows3, { emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
        const updatedRows = rows1.map(row => row.id === id ? { ...row } : row);
        const firstRow = updatedRows[0];

        // Reset perDiem and perDiem1
        if (firstRow) {
            firstRow.perDiem = 0;
            firstRow.perDiem1 = 0;
            firstRow.to_city = '';
        }
    };

    const handleRemoveEmpRow = async (id) => {
        // Find the row to remove
        const rowToRemove = rows3.find(row => row.id === id);

        // Check if the row is empty (i.e., no values in the key fields)
        if (!rowToRemove ||
            (!rowToRemove.emp_code && !rowToRemove.comp_name && !rowToRemove.dept_name &&
                !rowToRemove.designation_name && !rowToRemove.grade && !rowToRemove.mobile)) {

            // If the row is empty, remove it directly from state
            setRows3(prevRows => prevRows.filter(row => row.id !== id));
            toast.success("Employee row deleted successfully.");
            return;
        }

        // For non-empty rows, ask for user confirmation
        const userConfirmed = window.confirm(`Are you sure you want to delete this employee row?`);

        if (!userConfirmed) return; // Exit if the user cancels

        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${API_BASE_URL}setSettlement3Delete?id=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setRows3(prevRows => prevRows.filter(row => row.id !== id));
                toast.success("Employee row deleted successfully.");
            } else {
                toast.error(result.message || "Failed to update employee row status.");
            }
        } catch (error) {
            console.error('Error setting employee row status inactive:', error);
            toast.error("Failed to set employee row status inactive.");
        }
    };


    const handleAddTravelRow = () => {
        setrows1([...rows1, { travel_id: id, date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };




    const handleRemoveTravelRow = async (id) => {
        // Find the row to remove
        const rowToRemove = rows1.find(row => row.id === id);

        // Check if the row is empty (i.e., no values in the key fields)
        if (!rowToRemove ||
            (!rowToRemove.date && !rowToRemove.time && !rowToRemove.travelType &&
                !rowToRemove.from_city && !rowToRemove.to_city && !rowToRemove.hotel_amount &&
                !rowToRemove.ticket_travel_amount && !rowToRemove.perDiem && !rowToRemove.local &&
                !rowToRemove.misc)) {

            // If the row is empty, remove it directly from state
            setrows1(prevRows => prevRows.filter(row => row.id !== id));
            toast.success("Travel row deleted successfully.");
            return;
        }

        // For non-empty rows, ask for user confirmation
        const userConfirmed = window.confirm(`Are you sure you want to delete this travel row?`);

        if (!userConfirmed) return; // Exit if the user cancels

        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        try {
            // Make API call to set status inactive
            const response = await fetch(`${API_BASE_URL}setSettlement4Delete?id=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                // If API call was successful, remove the row
                setrows1(prevRows => prevRows.filter(row => row.id !== id));
                toast.success("Travel row deleted successfully.");
            } else {
                toast.error(result.message || "Failed to update travel row status.");
            }
        } catch (error) {
            console.error('Error setting travel row status inactive:', error);
            toast.error("Failed to set travel row status inactive.");
        }
    };


    const handleEmpRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows3(prevRows => prevRows.map(row => row.id === id ? { ...row, [name]: value } : row));
        if (name === 'emp_code') {
            const emp_code = parseFloat(value) || 0;
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
        const updatedRows = rows1.map(row => row.id === id ? { ...row, [name]: value } : row);
        const firstRow = updatedRows[0];

        // Reset perDiem and perDiem1
        if (firstRow) {
            firstRow.perDiem = 0;
            firstRow.perDiem1 = 0;
            firstRow.to_city = '';
        }
    };

    const handleTravelRowChange = (id, e) => {
        const { name, value } = e.target;
        // Convert numeric values to float
        const numericValue = name === 'hotel_amount' || name === 'ticket_travel_amount' || name === 'misc' ? parseFloat(value) || 0 : value;

        setrows1(prevRows => prevRows.map(row =>
            row.id === id ? { ...row, [name]: numericValue } : row
        ));
    };

    const handleGuesthouseChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // You can add any additional logic related to the guesthouse here.
    };

    const handleTravelTypeChange = (id, event) => {
        const { name, value } = event.target;

        const updatedRows = travelRows.map(row =>
            row.id === id ? { ...row, [name]: value } : row
        );

        setTravelRows(updatedRows);
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel Settlement Form(Shared)</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/own_settlement">Settlement Details</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8" encType="multipart/form-data">
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <div className="box-body">
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="emp_code"
                                                                    id="name"
                                                                    value={formData.emp_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Employee No/Consultant"
                                                                    readonly="true"

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="comp_name"
                                                                    id="name"
                                                                    value={formData.comp_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    placeholder="Name"
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Employee Band<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="grade"
                                                                    id="name"
                                                                    value={formData.grade}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="dept_name"
                                                                    id="name"
                                                                    value={formData.dept_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="designation_name"
                                                                    id="name"
                                                                    value={formData.designation_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Mobile No
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="mobile"
                                                                    id="name"
                                                                    value={formData.mobile}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="row">

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="days">No of Days
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    name="days"
                                                                    id="days"
                                                                    value={formData.days}
                                                                    onChange={(e) => handleChange('days', e)}
                                                                    onBlur={validateDays}
                                                                    className="form-control input-font"
                                                                    placeholder="1,1.5,2,2.5, etc"
                                                                    required



                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="travel_advance"
                                                                    id="travelAdvance"
                                                                    value={formData.travel_advance}
                                                                    onChange={(e) => handleChange('travel_advance', e)}
                                                                    className="form-control input-font"
                                                                    required
                                                                >

                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>


                                                        {/* <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="travelAmount">Travel Advance Amount <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="number"
                                                                    name="travel_amount"
                                                                    id="travelAmount"
                                                                    value={Amount}
                                                                    onChange={(e) => handleChange('travel_amount', e)} // Handle change for travel amount
                                                                    className="form-control input-font"
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="purpose">Advance Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="purpose"
                                                                    id="purpose"
                                                                    value={Purpose}
                                                                    onChange={(e) => handleChange('purpose', e)} // Handle change for purpose
                                                                    className="form-control input-font"
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div> */}

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="settlement_purpose"
                                                                    id="settlement_purpose"
                                                                    value={formData.settlement_purpose}
                                                                    onChange={(e) => handleChange('purpose', e)} // Handle change for purpose
                                                                    className="form-control input-font"
                                                                    required

                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Reporting Manager
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="report_mngr"
                                                                    id="name"
                                                                    value={formData.report_mngr}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Location
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="report_mngr"
                                                                    id="name"
                                                                    value={formData.location_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                                    <span style={{ color: 'red' }}>*</span>
                                                                </label>
                                                                <select
                                                                    required
                                                                    name="guesthouse"
                                                                    id="guesthouse"
                                                                    value={formData.guesthouse}
                                                                    onChange={handleGuesthouseChange}
                                                                    className="form-control input-font"
                                                                >
                                                                    <option value="">Select a Guest-House</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Vishakapatnam">Vishakapatnam</option>
                                                                    <option value="Rajuhmundry">Rajuhmundry</option>
                                                                    <option value="Gurgaon">Gurgaon</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_acnum">Bank Ac Num
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_acnum"
                                                                    id="bank_acnum"
                                                                    value={formData.bank_acnum}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="bank_name">Bank Name
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    value={formData.bank_name}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="ifsc_code">IFSC Code
                                                                    <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    name="ifsc_code"
                                                                    id="ifsc_code"
                                                                    value={formData.ifsc_code}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"

                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                    {/* <button type="button" onClick={handleAddEmpRow} className="btn btn-primary">+</button> */}
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Emp Code</th>
                                                                <th>Name</th>
                                                                <th>Dept</th>
                                                                <th>Designation</th>
                                                                <th>Grade</th>
                                                                <th>Mobile</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows3.map(row => (
                                                                <tr key={row.id}>
                                                                    <td data-label="Emp Code">
                                                                        <input
                                                                            type="text"
                                                                            name="emp_code"
                                                                            value={row.emp_code}
                                                                            onChange={(e) => {
                                                                                handleEmpRowChange(row.id, e);
                                                                                setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                            }}
                                                                            className="form-control input-font"
                                                                            placeholder="Emp Code"
                                                                            required
                                                                        />
                                                                        {filteredCodes.length > 0 && (
                                                                            <ul className="suggestions">
                                                                                {filteredCodes.map((code) => (
                                                                                    <li key={code} onClick={() => handleSelectCode(code)}>
                                                                                        {code}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </td>
                                                                    <td data-label="Name">
                                                                        <input
                                                                            type="text"
                                                                            name="comp_name"
                                                                            value={row.comp_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Name"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Dept">
                                                                        <input
                                                                            type="text"
                                                                            name="dept_name"
                                                                            value={row.dept_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Dept"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Designation">
                                                                        <input
                                                                            type="text"
                                                                            name="designation_name"
                                                                            value={row.designation_name}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Designation"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Grade">
                                                                        <input
                                                                            type="text"
                                                                            name="grade"
                                                                            value={row.grade}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Grade"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    <td data-label="Mobile">
                                                                        <input
                                                                            type="text"
                                                                            name="mobile"
                                                                            value={row.mobile}
                                                                            onChange={(e) => handleEmpRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="Mobile"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    {/* <td data-label="Actions">
                                                                        <button type="button" onClick={() => handleRemoveEmpRow(row.id)} className="btn btn-danger">×</button>
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                    {/* <button type="button" onClick={handleAddTravelRow} className="btn btn-primary">+</button> */}
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr className="header-row">
                                                                <th>Date</th>
                                                                <th>Time</th>
                                                                <th>Travel Type</th>
                                                                <th>From City</th>
                                                                <th>To City</th>
                                                                <th>Hotel Booked By</th>
                                                                <th>Hotel Receipt</th>
                                                                <th>Hotel Amount</th>
                                                                <th>Travel Ticket Booked By</th>
                                                                <th>Ticket Receipt</th>
                                                                <th>Travel Amount</th>
                                                                <th>Per Diem Allow</th>

                                                                <th>Local Conveyance</th>
                                                                <th>Misc</th>
                                                                <th>Total Rs</th>
                                                                {/* <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {rows1.map((row, index) => (
                                                                <tr key={row.id}>
                                                                    <td data-label="Date">
                                                                        <input
                                                                            type="date"
                                                                            name="date"
                                                                            value={row.date}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            max={new Date().toISOString().split("T")[0]}
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Time">
                                                                        <input
                                                                            type="time"
                                                                            name="time"
                                                                            value={row.time}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Travel Type">
                                                                        <select name="travelType" value={row.travelType} onChange={(e) => handleTravelTypeChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Travel Type</option>

                                                                            {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    <option value='Own_Car'>Own Car</option>
                                                                                </>

                                                                            ) : (
                                                                                <>
                                                                                    <option value='Air'>Air</option>
                                                                                    <option value='Train'>Train</option>
                                                                                    <option value='Bus'>Bus</option>
                                                                                    <option value='Local_Taxi'>Local Taxi</option>
                                                                                    <option value='Local_Auto'>Local Auto</option>
                                                                                    <option value='Own_Bike'>Own Bike</option>
                                                                                    {/* <option value='Own_Car'>Own Car</option> */}
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="From Place">
                                                                        <select name="from_city" value={row.from_city} onChange={(e) => handleTravelRowChange(row.id, e)} className="form-control input-font">
                                                                            <option value="">Select a Tour Place</option>
                                                                            {items.map(city => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="To Place">
                                                                        <select name="to_city" value={row.to_city} onChange={(e) => handleCityChange(row.id, e)} className="form-control input-font">
                                                                            <option value="">Select a Tour Place</option>
                                                                            {items.map(city => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleTravelRowChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {row.hotel_receipt && (
                                                                                <a href={row.hotel_receipt} target="_blank1" rel="noopener noreferrer" className="position-relative">
                                                                                    <img
                                                                                        src={icon1}
                                                                                        alt="Receipt"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }} // Add rounded corners
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td data-label="Hotel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="hotel_amount"
                                                                            value={row.hotel_amount}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.hotel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Booked by">
                                                                        <select name="ticket_travel_booked_by" value={row.ticket_travel_booked_by} onChange={(e) => handleTravelRowChange(row.id, e)} className="form-control input-font" required>
                                                                            <option value="">Select Booked By</option>
                                                                            <option value="Self">Self</option>
                                                                            <option value="TravelDesk">Travel Desk</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {row.ticket_receipt && (
                                                                                <a href={row.ticket_receipt} target="_blank1" rel="noopener noreferrer" className="position-relative">
                                                                                    <img
                                                                                        src={icon1}
                                                                                        alt="Receipt"
                                                                                        style={{ width: '24px', height: '24px', cursor: 'pointer' }} // Add rounded corners
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td data-label="Travel Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="ticket_travel_amount"
                                                                            value={row.ticket_travel_amount}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                        />
                                                                    </td>
                                                                    <td data-label="Per Diem Allow">
                                                                        <input
                                                                            type="number"
                                                                            name="perDiem"
                                                                            value={
                                                                                row.perDiem
                                                                            }
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            required
                                                                            disabled={index !== 0} // Disable for other rows if needed
                                                                        />
                                                                    </td>
                                                                    <td data-label="Local Conveyance">
                                                                        <input
                                                                            type="number"
                                                                            name="local"
                                                                            value={index === 0 ? totalLocalConveyance : ''} // Set only in the first row
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                            disabled={index !== 0} // Disable for other rows
                                                                        />
                                                                    </td>


                                                                    <td data-label="Misc">
                                                                        <input
                                                                            type="number"
                                                                            name="misc"
                                                                            value={row.misc}
                                                                            onChange={(e) => handleTravelRowChange(row.id, e)}
                                                                            className="form-control input-font"
                                                                        />
                                                                    </td>
                                                                    <td data-label="Total Rs.">
                                                                        <input
                                                                            type="number"
                                                                            value={parseFloat(
                                                                                (parseFloat(row.perDiem)) +
                                                                                (parseFloat(row.hotel_amount) || 0) +
                                                                                (parseFloat(row.ticket_travel_amount) || 0) +
                                                                                (parseFloat(row.misc) || 0) +
                                                                                (index === 0 ? (parseFloat(totalLocalConveyance) || 0) : 0)
                                                                            )}
                                                                            className="form-control input-font"
                                                                            readOnly
                                                                        />
                                                                    </td>
                                                                    {/* <td>
                                                                        <button type="button" onClick={() => handleRemoveTravelRow(row.id)} className="btn btn-danger">
                                                                            <i className="fas fa-times"></i>
                                                                        </button>
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>

                                                        <tfoot>
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Grand Total</th>
                                                                <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                <th></th>
                                                            </tr>

                                                            {/* <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Less Advance</th>
                                                                <th style={{ textAlign: 'center' }}>{Amount}</th>
                                                                <th></th>
                                                            </tr> */}
                                                            <tr>
                                                                <th colSpan="14" style={{ textAlign: 'right' }}>Total</th>
                                                                <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                                <th></th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </fieldset>
                                            <br />
                                            <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                <center><span className='btn btn-primary' style={{ fontSize: '12px' }}>Details of Local Conveyance</span></center>


                                                <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                                    {/* <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button> */}
                                                    <table className="expense-table">
                                                        <thead>
                                                            <tr>
                                                                <td colSpan="8"></td>
                                                            </tr>
                                                            <tr className="header-row">

                                                                <th style={{ textAlign: 'center' }}>Travel Date</th>
                                                                <th style={{ textAlign: 'center' }}>Origin</th>
                                                                <th style={{ textAlign: 'center' }}>Destination</th>
                                                                <th style={{ textAlign: 'center' }}>Amount</th>
                                                                <th style={{ textAlign: 'center' }}>Transport Mode</th>
                                                                <th style={{ textAlign: 'center' }}>Receipt/Bill</th>
                                                                {/* <th>Actions</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {transportRows.map((row, index) => (
                                                                <tr key={index}>

                                                                    <td data-label="Travel Date">
                                                                        <input
                                                                            type="date"
                                                                            name="travelDate"
                                                                            value={row.travelDate}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            max={new Date().toISOString().split("T")[0]}
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Origin">
                                                                        <input
                                                                            type="text"
                                                                            name="origin"
                                                                            value={row.origin}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="From"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Destination">
                                                                        <input
                                                                            type="text"
                                                                            name="destination"
                                                                            value={row.destination}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            placeholder="To"
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Amount">
                                                                        <input
                                                                            type="number"
                                                                            name="travelAmount"
                                                                            value={row.travelAmount}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"

                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td data-label="Transport Mode">
                                                                        <select
                                                                            name="transportMethod"
                                                                            value={row.transportMethod}
                                                                            onChange={(e) => handleTransportChange(index, e)}
                                                                            className="form-control input-font"
                                                                            required
                                                                        >
                                                                            <option value="">Select Mode</option>
                                                                            <option value="bus">Bus</option>
                                                                            <option value="auto">Auto</option>
                                                                            <option value="taxi">Taxi</option>
                                                                            <option value="Car">Own Vehicle-Car</option>
                                                                            <option value="Bike">Own Vehicle-Bike</option>
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <input
                                                                                type="file"
                                                                                name="receipt"
                                                                                accept="image/*,application/pdf"
                                                                                disabled
                                                                                className="form-control input-font me-2" // Add margin to separate the input and image
                                                                            />
                                                                            {row.receipt && (
                                                                                <a href={row.receipt} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                    <img
                                                                                        src={icon1}
                                                                                        alt="Receipt"
                                                                                        style={{ width: '50px', height: '50px', borderRadius: '4px' }} // Add rounded corners
                                                                                    />

                                                                                </a>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td data-label="Actions">
                                                                        <button type="button" onClick={() => handleRemoveTransportRow(row.id, row.travelAmount, grandTotal() - parseFloat(formData.travel_amount))} className="btn btn-danger">×</button>
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="3" style={{ textAlign: 'right' }}>Total Amount:</td>
                                                                <td>{totalLocalConveyance}</td>
                                                                <td colSpan="3"></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                                {flashAlert && (
                                                <div className="flash-alert" style={{ color: 'red', marginBottom: '10px', fontSize: '14px' }}>
                                                    {flashAlert}
                                                </div>
                                                )}
                                                <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                                    <center><span className="btn btn-primary" style={{ fontSize: '12px' }}>Additional Documents</span></center>
                                                    <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>

                                                        <table className="document-table" style={{ marginTop: '10px' }}>
                                                            <thead>
                                                                <tr>

                                                                    <th style={{ textAlign: 'center' }}>Click to Preview</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* Loop through documentsRows and display each document's image */}
                                                                {documentsRows.length > 0 ? (
                                                                    <tr>
                                                                        <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                                                                {documentsRows.map((document) => (
                                                                                    <div key={document.id} style={{ margin: '10px' }}>
                                                                                        <a href={document.additional_doc} target="_blank" rel="noopener noreferrer" className="position-relative">
                                                                                            {document.additional_doc.toLowerCase().endsWith('.pdf') ? (
                                                                                                // If the document is a PDF, show the PDF icon
                                                                                                <img
                                                                                                    src={pdficon}// Replace with the actual path to your PDF icon
                                                                                                    alt={`PDF Document ${document.id}`}
                                                                                                    style={{
                                                                                                        width: '100px',
                                                                                                        height: '100px',
                                                                                                        objectFit: 'cover',
                                                                                                        margin: '5px',
                                                                                                    }}
                                                                                                />
                                                                                            ) : (
                                                                                                // Otherwise, show the image
                                                                                                <img
                                                                                                    src={document.additional_doc}
                                                                                                    alt={`Document ${document.id}`}
                                                                                                    style={{
                                                                                                        width: '79px',
                                                                                                        height: '100px',
                                                                                                        objectFit: 'cover',
                                                                                                        margin: '5px',
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </a>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="1" style={{ textAlign: 'center' }}>
                                                                            {error ? error : 'No documents available.'}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <div className="form-group">
                                                        <label htmlFor="documents">Upload Documents</label>
                                                        {(formData.rpt_mgr_status === 'Pending' || formData.audit_status === 'Rejected' || formData.rpt_mgr_status === 'Rejected' || formData.audit_status === 'Pending') && (
                                                            <input
                                                                type="file"
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                                onChange={handleFileChange}
                                                                name="additional_doc"
                                                                multiple
                                                                className="form-control input-font"
                                                            />
                                                        )}
                                                    </div>
                                                    {/* Display the selected file names */}
      {selectedFiles.length > 0 && (
        <div className="selected-files">
          <h5>Selected Files:</h5>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
                                                    </div>
                                                </fieldset>
                                                <div class="row">{formData.rpt_mgr_status === 'Approved' && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="rpt_mgr_status">Manager Status
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            name="rpt_mgr_status"
                                                            id="rpt_mgr_status"
                                                            value={formData.rpt_mgr_status}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                            disabled
                                                            required
                                                        >

                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                    </div>
                                                </div>)}
                                                    {formData.manager_approved_amount > 0 && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Manager Approved Amount
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="manager_approved_amount"
                                                                id="name"
                                                                required
                                                                value={formData.manager_approved_amount}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Manager Approved Amount"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>)}
                                                    {(formData.manager_remarks !== '' && formData.rpt_mgr_status === 'Approved') && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="remarks">Manager Remarks
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>

                                                            <textarea name="manager_remarks"
                                                                readonly="true"
                                                                id="remarks"
                                                                required
                                                                value={formData.manager_remarks}
                                                                onChange={handleChange}
                                                                className="form-control "
                                                                placeholder="Remarks"></textarea>
                                                        </div>
                                                    </div>)}
                                                </div>
                                                <div class="row">{formData.audit_status === 'Approved' && (<div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="audit_status">Audit Status
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            name="audit_status"
                                                            id="audit_status"
                                                            value={formData.audit_status}
                                                            onChange={handleChange}
                                                            required
                                                            className="form-control input-font"
                                                            readonly="true"
                                                            disabled
                                                        >

                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                    </div>
                                                </div>)}
                                                    {formData.audit_approved_amount > 0 && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="name">Audit Approved Amount
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                required
                                                                name="audit_approved_amount"
                                                                id="name"
                                                                value={formData.audit_approved_amount}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Audit Approved Amount"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>)}
                                                    {formData.audit_remarks !== '' && (<div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="remarks">Audit Remarks
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>

                                                            <textarea name="audit_remarks"
                                                                readonly="true"
                                                                id="remarks"
                                                                value={formData.audit_remarks}
                                                                onChange={handleChange}
                                                                className="form-control "
                                                                required
                                                                placeholder="Remarks"></textarea>
                                                        </div>
                                                    </div>)}
                                                </div>
                                                <div className="row">{formData.account_status === 'Approved' && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="account_status">Finance Status
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="account_status"
                                                                id="account_status"
                                                                value={formData.account_status}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                readonly="true"
                                                            >
                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>)}

                                                    {formData.finance_approved_amount > 0 && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Finance Approved Amount</label>
                                                                <span style={{ color: 'red' }}>*</span>
                                                                <input
                                                                    type="text"
                                                                    name="accounts_approved_amount"
                                                                    id="name"
                                                                    value={formData.finance_approved_amount}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>)}


                                                    {formData.finance_remarks !== '' && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="remarks">Finance Remarks</label>
                                                                <span style={{ color: 'red' }}>*</span>
                                                                <textarea
                                                                    name="accounts_remarks"
                                                                    id="remarks"
                                                                    value={formData.finance_remarks}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    required
                                                                    readonly="true"
                                                                ></textarea>
                                                            </div>
                                                        </div>)}
                                                    {(formData.rpt_mgr_status === 'Rejected' || formData.audit_status === 'Rejected' || formData.account_status === 'Rejected') && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label style={{ fontSize: '12px' }} htmlFor="status_select">
                                                                    Request Type

                                                                    <span style={{ color: 'red' }}>*</span>
                                                                    (<small style={{ color: '#888', fontStyle: 'italic' }}>
                                                                        For Requesting Again to the Reporting Manager or Hotel Manager make this "Pending".
                                                                    </small>)
                                                                </label>

                                                                <select
                                                                    name="status_select"
                                                                    id="status_select"
                                                                    value={formData.status_select || ''}
                                                                    onChange={handleChange2}
                                                                    className="form-control input-font"
                                                                    required
                                                                >
                                                                    <option value="">Select Request</option>

                                                                    {/* Conditionally enable only the appropriate option */}
                                                                    {formData.rpt_mgr_status === 'Rejected' && (
                                                                        <option value="request_for_manager">Request For Manager</option>
                                                                    )}
                                                                    {formData.audit_status === 'Rejected' && (
                                                                        <option value="request_for_audit_mngr">Request For Audit Manager</option>
                                                                    )}
                                                                    {formData.account_status === 'Rejected' && (
                                                                        <option value="request_for_account_mngr">Request For Finance Manager</option>
                                                                    )}

                                                                </select>
                                                            </div>



                                                        </div>

                                                    )}


                                                    {/* Based on selected Request Type, show the corresponding dropdowns */}
                                                    {formData.status_select && (
                                                        <div className="col-md-4">
                                                            {formData.status_select === 'request_for_manager' && (
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">
                                                                        Request For Manager
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        name="rpt_mgr_status"
                                                                        id="rpt_mgr_status"
                                                                        value={formData.rpt_mgr_status || ''}
                                                                        onChange={handleChange2}
                                                                        className="form-control input-font"
                                                                        required
                                                                    >
                                                                        <option value="Pending">Pending</option>
                                                                    </select>
                                                                </div>
                                                            )}

                                                            {formData.status_select === 'request_for_audit_mngr' && (
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="audit_status">
                                                                        Request For Audit Manager
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        name="audit_status"
                                                                        id="audit_status"
                                                                        value={formData.audit_status || ''}
                                                                        onChange={handleChange2}
                                                                        className="form-control input-font"
                                                                        required
                                                                    >
                                                                        <option value="Pending">Pending</option>
                                                                    </select>
                                                                </div>
                                                            )}

                                                            {formData.status_select === 'request_for_account_mngr' && (
                                                                <div className="form-group">
                                                                    <label style={{ fontSize: '12px' }} htmlFor="account_status">
                                                                        Request For Finance Manager
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                    </label>
                                                                    <select
                                                                        name="account_status"
                                                                        id="account_status"
                                                                        value={formData.account_status || ''}
                                                                        onChange={handleChange2}
                                                                        className="form-control input-font"
                                                                        required
                                                                    >
                                                                        <option value="Pending">Pending</option>
                                                                    </select>
                                                                </div>
                                                            )}


                                                        </div>
                                                    )}

                                                </div>
                                                <div class="row">{formData.utr !== '' && (
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor="name">UTR
                                                            </label><span style={{ color: 'red' }}>*</span>
                                                            <input
                                                                type="text"
                                                                name="utr"
                                                                id="name"
                                                                value={formData.utr}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="UTR"
                                                                required
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>)}{formData.utr_date !== '' && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="date">Date</label><span style={{ color: 'red' }}>*</span>
                                                                <input
                                                                    type="date"
                                                                    name="utr_date"
                                                                    id="utr_date" // Use a unique id
                                                                    value={formData.utr_date} // Bind it to your form state
                                                                    onChange={handleChange} // Handle changes with the same function
                                                                    className="form-control input-font"
                                                                    placeholder="Select a date" // Optional placeholder text
                                                                    required
                                                                    readonly="true"
                                                                />
                                                            </div>
                                                        </div>)}

                                                </div>
                                            </fieldset>

                                            <div className="box-footer" style={{ textAlign: 'center' }}>
                                                {formData.account_status !== 'Approved' && (
                                                    <button type="submit" className="btn btn-success pull-right">Submit</button>
                                                )}
                                                <button
                                                    className="btn btn-secondary  ml-3"
                                                    onClick={handleBackClick} // Handle redirect
                                                >
                                                    Cancel
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-sm-12 row" style={{ width: '100%' }}>
                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                        <b>Prepared by </b>
                                        <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                            : {formData.created_on}
                                        </p>
                                    </div>
                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                        {formData.rpt_mgr_status === 'Approved' && (
                                            <>
                                                <b> Approved by</b>
                                                <p> {formData.report_mngr} ({ReportManageName})<br /> Date
                                                    : {formData.manager_approved_date}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                        {formData.audit_status === 'Approved' && (
                                            <>
                                                <b> Verified by Audit Team</b>
                                                <p>                         <span>
                                                    {formData.audit_manager} ({AuditManageName} )   <br /> Date
                                                    : {formData.audit_approved_date}
                                                </span>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                        {formData.account_status === 'Approved' && (
                                            <>
                                                <b> Verified by Finance Team</b>
                                                <p>                         <span>
                                                    {formData.accounts_manager} ({AccountsManageName} )   <br /> Date
                                                    : {formData.finance_approved_date}
                                                </span>
                                                </p>
                                            </>
                                        )}
                                    </div>



                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default EditSettlementFormGroup;
