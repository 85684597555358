import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import useAuth from './useAuth';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LocalConveyanceForm = () => {
    const navigate = useNavigate();
    const [EmpCode, setEmpCode] = useState('');
    const [isSavingDraft, setIsSavingDraft] = useState(false);
    const [Grade, setGrade] = useState('');
    const [loading, setLoading] = useState(true);
    const [responseMessage, setResponseMessage] = useState('');
    const [formData, setFormData] = useState({
        comp_name: '',
        emp_code: '',
        grade: '',
        dept_name: '',
        designation_name: '',
        report_mngr: '',
        mode_of_travel: '',
        to_city: '',
        guesthouse: '',
        bank_acnum: '',
        bank_name: '',
        ifsc_code: '',
        img: []  // To store the image files
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [transportRows, setTransportRows] = useState([{
        id: 1,
        travelDate: '',
        day: '',
        origin: '',
        destination: '',
        distance: '',
        travelAmount: '',
        transportMethod: '',
        remarks: ''
    }]);

    useAuth();  // Custom hook for authentication

    const handleBackClick = () => {
        navigate("/manage_conveyance");
    };

    const handleAddTransportRow = () => {
        setTransportRows([
            ...transportRows,
            { id: transportRows.length + 1, travelDate: '', day: '', origin: '', destination: '', distance: '', travelAmount: '', transportMethod: '', remarks: '' }
        ]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTransportChange = (index, e) => {
        const { name, value } = e.target;
        const newTransportRows = [...transportRows];

        if (name === 'transportMethod') {
            newTransportRows[index].distance = '';
            newTransportRows[index].travelAmount = '';
        }

        if (name === 'distance' && newTransportRows[index].transportMethod === 'Bike') {
            const distance = parseFloat(value);
            newTransportRows[index].travelAmount = distance > 0 ? (distance * 3.5).toFixed(2) : '';
        }

        if (name === 'travelDate') {
            const date = new Date(value);
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            newTransportRows[index].day = daysOfWeek[date.getDay()];
        }

        newTransportRows[index] = { ...newTransportRows[index], [name]: value };
        setTransportRows(newTransportRows);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const fileReaders = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileReaders)
            .then(base64Files => {
                setFormData(prevData => ({
                    ...prevData,
                    img: base64Files // Store base64 encoded images
                }));
            })
            .catch(error => {
                console.error("Error reading files:", error);
            });
    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length > 1) {
            setTransportRows(transportRows.filter((_, i) => i !== index));
        }
    };


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result); // Log to check fetched data

                if (result.status) {
                    const { profile, travel_advance } = result;

                    // Set default values from profile
                    setEmpCode(profile.emp_code || '');
                    setGrade(profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        report_mngr: profile.report_mngr || '',
                        mobile: profile.mobile || '', // Do not display the password
                        bank_acnum: profile.bank_acnum || '',
                        bank_name: profile.bank_name || '',
                        ifsc_code: profile.ifsc_code || '',
                        location_name: profile.location_name || '',
                    }));


                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchDraft = async (empCode) => {
            if (!empCode) return; // Prevent API call if empCode is empty
    
            const token = localStorage.getItem('authToken'); // Get token from localStorage
            if (!token) {
                console.error("No auth token found.");
                return;
            }
    
            try {
                const response = await fetch(`${API_BASE_URL}get-draft/${empCode}`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`, // Pass token
                        "Content-Type": "application/json"
                    }
                });
    
                if (!response.ok) {
                    throw new Error(`Failed to fetch draft. Status: ${response.status}`);
                }
    
                const data = await response.json();
                if (data) {
                    setFormData(prevData => ({ ...prevData, ...data }));
                }
            } catch (error) {
                console.error("Error loading draft:", error);
            }
        };
    
        if (EmpCode) {
            fetchDraft(EmpCode);
        }
    }, [EmpCode]); // Runs when EmpCode updates
    
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const totalDistanceConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.distance) || 0), 0);
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        const dataToSubmit = {
            ...formData,
            transportRows: transportRows,
            total_localconveyance: totalLocalConveyance,  // Add the total local conveyance
            total_km: totalDistanceConveyance
        };

        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}postLocalConveyance`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'  // Sending JSON payload
                },
                body: JSON.stringify(dataToSubmit) // Convert the data to JSON format
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Local Conveyance Form submitted successfully!');
                navigate('/manage_conveyance');
            } else {
                toast.error(result.message || 'An error occurred while submitting the form.');
            }
        } catch (error) {
            console.error('Submission error:', error);
            toast.error('Failed to submit form');
        } finally {
            setIsSubmitting(false);
        }
    };

    const dataToSubmit1 = {
        ...formData,
        transportRows: transportRows,
        total_localconveyance: totalLocalConveyance,  // Add the total local conveyance
        total_km: totalDistanceConveyance
    };

    const saveDraft = async () => {
        setIsSavingDraft(true);
        
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}saveLocalConveyanceDraft`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'  // Sending JSON payload
                },
                body: JSON.stringify(dataToSubmit1) // Convert the data to JSON format
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Local Conveyance Form submitted successfully!');
                navigate('/manage_conveyance');
            } else {
                toast.error(result.message || 'An error occurred while submitting the form.');
            }
        } catch (error) {
            console.error('Submission error:', error);
            toast.error('Failed to submit form');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Local Conveyance Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active">Local Conveyance Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #CC5500' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8">
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <div className="box-body">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name </label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band</label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department</label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation </label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_name">Bank Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_name"
                                                            id="bank_name"
                                                            value={formData.bank_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_acnum">Bank Ac.No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_acnum"
                                                            id="bank_acnum"
                                                            value={formData.bank_acnum}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="ifsc_code">IFSC Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="ifsc_code"
                                                            id="ifsc_code"
                                                            value={formData.ifsc_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.location_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center>
                                            <span className='btn btn-warning' style={{ fontSize: '12px', color: 'white', backgroundColor: '#CC5500' }}>
                                                Details of Local Conveyance
                                            </span>
                                        </center>
                                        <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button>
                                            <table className="expense-table">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="12"></td>
                                                    </tr>
                                                    <tr className="header-row">
                                                        <th style={{ textAlign: 'center' }}>Travel Date<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Day</th>
                                                        <th style={{ textAlign: 'center' }}>Origin<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Destination<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Transport Mode<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Distance KM<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Amount<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Remarks</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transportRows.map((row, index) => (
                                                        <tr key={index}>
                                                            <td data-label="Travel Date" style={{ width: '100px' }}>
                                                                <input
                                                                    type="date"
                                                                    name="travelDate"
                                                                    value={row.travelDate}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    max={new Date().toISOString().split("T")[0]}
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Day" style={{ width: '100px' }}>
                                                                <input
                                                                    type="text"
                                                                    name="day"
                                                                    value={row.day}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="day"
                                                                    required={index === 0}
                                                                    disabled
                                                                />
                                                            </td>

                                                            <td data-label="Origin" style={{ width: '200px' }}>
                                                                <input
                                                                    type="text"
                                                                    name="origin"
                                                                    value={row.origin}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="From"
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Destination" style={{ width: '200px' }}>
                                                                <input
                                                                    type="text"
                                                                    name="destination"
                                                                    value={row.destination}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="To"
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Transport Mode" style={{ width: '200px' }}>
                                                                <select
                                                                    name="transportMethod"
                                                                    value={row.transportMethod}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font custom-select"
                                                                    required={index === 0}
                                                                >
                                                                    <option value="">Select Mode</option>
                                                                    <option value="bus">Bus</option>
                                                                    <option value="auto">Auto</option>
                                                                    <option value="taxi">Taxi</option>
                                                                    <option value="Car">Own Vehicle-Car</option>
                                                                    <option value="Bike">Own Vehicle-Bike</option>
                                                                    <option value="Company_Car">Company Car</option>
                                                                </select>
                                                            </td>

                                                            <td data-label="Distance" style={{ width: '200px' }}> {/* Reduced width for distance */}
                                                                <input
                                                                    type="text"
                                                                    name="distance"
                                                                    value={row.distance}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="Enter distance"
                                                                    required={index === 0}
                                                                />
                                                            </td>

                                                            <td data-label="Amount" style={{ width: '200px' }}> {/* Reduced width for amount */}
                                                                <input
                                                                    type="text"
                                                                    name="travelAmount"
                                                                    value={row.travelAmount}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    required={index === 0}
                                                                />
                                                            </td>

                                                            <td data-label="Remarks" style={{ width: '400px' }}> {/* Increased width for remarks */}
                                                                <input
                                                                    type="text"
                                                                    name="remarks"
                                                                    value={row.remarks}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    // required={index === 0}
                                                                />
                                                            </td>

                                                            <td data-label="Actions">
                                                                <button type="button" onClick={() => handleRemoveTransportRow(index)} className="btn btn-danger">×</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: 'right', fontSize: '17px' }}>Grand Total :</td>
                                                        <td style={{ fontSize: '17px' }}><b>{totalDistanceConveyance} km</b></td>
                                                        <td style={{ fontSize: '17px' }}><b>₹{totalLocalConveyance}</b></td>
                                                        <td colSpan="5"></td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>
                                    </fieldset>
                                    <br />
                                    <section className="col-lg-12">
                                        <label>Upload Multiple Images:</label>
                                        <input
                                            type="file"
                                            name="img"
                                            multiple
                                            accept="image/*"
                                            onChange={handleFileChange}
                                        />
                                        <div className="box-footer">
    <center>
        {/* Save as Draft Button */}
        <button
            type="button"
            className="btn btn-warning"
            onClick={saveDraft}
        >
            {isSavingDraft ? 'Saving...' : 'Save as Draft'}
        </button>

        {/* Submit Button */}
        <button
            type="submit"
            className="btn btn-success ml-3"
            disabled={isSubmitting}
        >
            {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>

        {/* Cancel Button */}
        <button 
            type="button" 
            className="btn btn-secondary ml-3" 
            onClick={handleBackClick}
        >
            Cancel
        </button>
    </center>
</div>
                                    </section>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default LocalConveyanceForm;
